import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import Modal from "react-modal";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { callAdminApi } from "../../API/ApiInterface";
import EditAdmin from "./EditAdmin";
import Permissions from "./Permissions";
import { customStyles } from "../../CustomStyle";
export default function SubAdminItem({ contacts }) {
  const { setUserDeleted,userUpdated } = useContext(AppContext);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [userDetail, setuserDetail] = useState({});
  const [modalPermIsOpen, setPermModalOpen] = useState(false);





const editDetails=(id)=>{
const findItem=contacts.find((item)=>item.id===id)

if(findItem)
{
setuserDetail(findItem)
setModalOpen(true)




  }
}


const editPermissions=(id)=>{
  const findItem=contacts.find((item)=>item.id===id)
  
  if(findItem)
  {
  setuserDetail(findItem)
  setPermModalOpen(true)
  

    }
  }
   

 
useEffect(()=>{
  setModalOpen(false)
  setPermModalOpen(false)
},[userUpdated])

  const deleteuser = (event, id) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => acceptFunc(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });

    const acceptFunc = (id) => {
      callAdminApi(process.env.REACT_APP_BASEURL + "/admin/delete", 
       "DELETE",
       {type:'json',
        body: JSON.stringify({
          id: id,
        })
        // body:JSON.stringify({leads:selctedIds})
      })
        .then(async (data) => {

          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setUserDeleted(true);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          toast.success(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  };

  return (
    <div
      id="customers"
      className="infowindow overflow-auto"
      style={{ height: "500px" }}
    >
      <table>
        <thead>
          <tr>
            <th>Sr. No</th>

            <th>Name</th>
            <th>Email</th>
            <th>Code</th>
            <th>Contact</th>
            <th>Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((user, index) => (
            <tr key={"_" + index + "_" + user.id}>
              <td>{index + 1}</td>
              <td>{user.firstName + " " + user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.code}</td>
              <td>+{user.countryCode+"-"+user.phoneNumber}</td>
              <td>{user.role===1?"Sub Admin":"Manager"}</td>

              <td>
                <button
                  onClick={(e) => deleteuser(e, user.id)}
                  className="btn btn-xs btn-danger"
                >
                  Delete
                </button>{" "}
                <button
                  onClick={(e) => editDetails(user.id)}
                  className="btn btn-info  mx-1 btn-xs"
                >
                  Edit
                </button>

                <button
                  onClick={(e) => editPermissions(user.id)}
                  className="btn btn-success  mx-1 btn-xs"
                >
                  Permissions
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        appElement={document.getElementById("root")}
        contentLabel="Example Modal"
      >
        <button onClick={(e)=>setModalOpen(false)}>Close</button>

       <EditAdmin userDetail={userDetail}></EditAdmin>
      </Modal>

      <Modal
        isOpen={modalPermIsOpen}
        style={customStyles}
        appElement={document.getElementById("root")}
        contentLabel="Example Modal"
      >
        <button onClick={(e)=>setPermModalOpen(false)}>Close</button>

       <Permissions adminId={userDetail.id}></Permissions>
      </Modal>
    </div>
  );
}
