import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { callAdminApi } from "../../API/ApiInterface";
import EditStatus from "./EditStatus"
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { customStyles } from "../../CustomStyle";
export default function StatusItem({ allStatus }) {
  const { userUpdated,setUserUpdated} = useContext(AppContext);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [statusDetail, setStatusDetail] = useState({});
 

  useEffect(() => {
    setModalOpen(false);
  }, [userUpdated]);

  const changeStatus = (status, id) => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/status/changeStatus",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({
          status: status,
          id: id,
        }),
        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        setUserUpdated(true)
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        toast.success(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


 

  const editDetails = (id) => {
    const findItem = allStatus.find((item) => item.id === id);

    if (findItem) {
      setStatusDetail(findItem);
      setModalOpen(true);
    }
  };

  return (
    <div
      id="customers"
      className="infowindow m-3 p-2 overflow-auto"
      style={{ height: "500px" }}
    >
      <table>
        <thead>
          <tr>
            <th>Status Name</th>
            <th>Plan Amount</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allStatus.map((item) => (
            <tr key={item.id}>
              <td>{item.statusName}</td>
              <td>{item.planAmount ? item.planAmount : 0}</td>
              <td>{item.active === 1 ? "Active" : "Inactive"}</td>
              <td>
                <button
                  onClick={() => changeStatus(!item.active, item.id)}
                  className={
                    item.active === 1
                      ? "btn btn-sm btn-info"
                      : "btn btn-sm btn-danger"
                  }
                >
                  {item.active === 1 ? "Disable" : "Enable"}
                </button>

                <button
                  onClick={(e) => editDetails(item.id)}
                  className="btn btn-warning  mx-1 btn-sm"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        appElement={document.getElementById("root")}
        contentLabel="Example Modal"
      >
        <button onClick={(e) => setModalOpen(false)}>Close</button>

        <EditStatus statusDetail={statusDetail}></EditStatus>
      </Modal>
    </div>
  );
}
