import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../AppContext";
import { callAdminApi } from "../../API/ApiInterface";
import LoadingSpinner from "../../Spinner";
import TemplateItem from "./TemplateItem";

export default function Templates() {
  const [templates, setAllTemplates] = useState([]);
  const [search, setSearch] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { deletedUser,setUserDeleted,userUpdated,setUserUpdated } = useContext(AppContext);

  useEffect(() => {
    callApi();
  }, []);

  const callApi = () => {
    setIsLoading(true)
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/templates/all?search=" + search +"&templateType="+templateType,
      "GET",
      {
        type: "json",
        auth: true,
      }
    )
      .then(async (data) => {
        setIsLoading(false)
        if (data.code === 200) {
          const result = data.body;

          const array = [];

          result.map((item) => {
            return array.push(item);
          });
          setAllTemplates([...array]);
          setUserDeleted(false)
          setUserUpdated(false)
        }
      })
      .catch((err) => {
        setIsLoading(false) 
        console.log(err)
      });
  };


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      callApi();
      // Send Axios request here
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])


  useEffect(() => {
    setSearch(search);
    callApi();
  }, [deletedUser,templateType,userUpdated]);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="bodyNav row">
        <div className="col-md-11 border row mx-2  my-3">
          <div className="col-md-5">
            <input
              type="text"
              placeholder="Search by (name,content)"
              value={search}
              className="form-control mt-2 mx-2 p-2"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="col-md-3 mx-2">
          <select
                  onChange={(e) => setTemplateType(e.target.value)}
                  className="form-select select2 mt-2 p-2"
                  aria-label=".form-select-lg example"
                >
                   <option key='option_0' value="">
                      Select Template Type
                    </option>

                    <option key='option_1' value="1">
                      EMAIL
                    </option>

                    
                    <option key='option_2' value="2">
                      SMS
                    </option>


                 
                </select>
          </div>

          <div className="col-md-12 m-3">
            <div>
              <p
                style={{ float: "left", fontSize: "20px" }}
                className="float-left"
              >
                Total Count: {templates.length}
              </p>
            </div>
            <TemplateItem templates={templates} />
          </div>
        </div>
      </div>
    </>
  );
}
