import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext";
import { toast } from "react-toastify";
import { callAdminApi } from "../../API/ApiInterface";

import {
  MDBBtn,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
  MDBRadio,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
export default function EditUser({ requestType, userDetail }) {
  const initialState = {
    firstName: userDetail.firstName,
    lastName: userDetail.lastName,
    email: userDetail.email,
    zipCode: userDetail.zipCode,
    householdSize: userDetail.householdSize,
    status: userDetail.mapStatus,
    address: userDetail.address,
    userId: userDetail.id,
    gotra: userDetail.gotra,
    requestType: requestType,
    paymentType: userDetail.paymentInfo?.paymentType || "",
    paymentPlan: userDetail.paymentInfo?.paymentPlanName || "",
    planAmount: userDetail.paymentInfo?.totalAmount || "",
    paidAmount: userDetail.paymentInfo?.paidAmount || "",
    remainingAmount: userDetail.paymentInfo?.remainingAmount || "",
    leadName: userDetail.paymentInfo?.leadName || "",
    mVCode: userDetail.paymentInfo?.verificationCode || "",
    isSpouseAdded: userDetail.spouseInfo ? "true" : "false",
    spFirstName: userDetail.spouseInfo?.firstName || "",
    spLastName: userDetail.spouseInfo?.lastName || "",
    comments: userDetail?.comments || "",
  };

  const { setUserUpdated, allStatus } = useContext(AppContext);
  const [inputData, setInputData] = useState(initialState);
  const [allOptions, setOptions] = useState([]);
  const [paymentDiv, setPaymentDiv] = useState(
    initialState.planAmount > 0 ? true : false
  );

  useEffect(() => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/useroptions/all",
      "GET",
      { type: "json", body: null, auth: true }
    )
      .then(async (data) => {
        if (data.code === 200) {
          setOptions([...data.body]);
          onMemberSelection(inputData.status);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();

    fetch(process.env.REACT_APP_BASEURL + "/users/editData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
      body: JSON.stringify(inputData),
    })
      .then(async (result) => {
        const data = await result.json();
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setUserUpdated(true);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const onChangepaidAmount = (value) => {
    const remaAmount = inputData.planAmount - value;
    inputData.remainingAmount = remaAmount <= 0 ? "0" : remaAmount;
    inputData.paidAmount = value;
    setInputData({ ...inputData });
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const checkVisibility = (code) => {
    if (requestType === "admin") return "inline";
    else {
      let dataFind = allOptions.findIndex(
        (ip) => ip.code === code && ip.public === 1
      );

      console.log(`data find is...`, code, dataFind);
      if (dataFind >= 0) return "inline";
      else return "none";
    }
  };
  const onMemberSelection = (statusValue) => {
    inputData.status = statusValue;
    const findItem = allStatus.find((item) => item.id === Number(statusValue));
    if (findItem && findItem.planAmount > 0) {
      setPaymentDiv(true);
      inputData.planAmount = findItem.planAmount;
    } else {
      setPaymentDiv(false);
      inputData.planAmount = 0;
    }

    const remaAmount = inputData.planAmount - inputData.paidAmount;
    inputData.remainingAmount = remaAmount;

    setInputData({ ...inputData });
  };

  return (
    <div
      id="customers"
      className="infowindow overflow-auto"
      style={{ height: "600px" }}
    >
      <form
        className="needs-validation"
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
      >
        <MDBCardBody className="text-black d-flex flex-column justify-content-center">
          <h3 className="mb-5  mt-3 text-uppercase fw-bold">
            Enter Details to update
          </h3>

          <MDBRow>
            <MDBCol md="6" style={{ display: checkVisibility("fname") }}>
              <MDBInput
                wrapperClass="mb-4"
                label="First Name"
                size="lg"
                name="firstName"
                maxLength={20}
                required={checkVisibility("fname") !== "none" ? true : false}
                value={inputData.firstName}
                onChange={onChange}
                type="text"
              />
            </MDBCol>

            <MDBCol md="6" style={{ display: checkVisibility("lname") }}>
              <MDBInput
                wrapperClass="mb-4"
                label="Last Name"
                size="lg"
                name="lastName"
                type="text"
                maxLength={20}
                required={checkVisibility("lname") !== "none" ? true : false}
                value={inputData.lastName}
                onChange={onChange}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow style={{ display: checkVisibility("email") }}>
            <MDBCol md="12">
              <MDBInput
                wrapperClass="mb-4"
                label="Email"
                size="lg"
                name="email"
                type="email"
                required={checkVisibility("email") !== "none" ? true : false}
                maxLength={40}
                value={inputData.email}
                onChange={onChange}
                autoComplete="off" 
              />
            </MDBCol>
          </MDBRow>

          <MDBCol style={{ display: checkVisibility("address") }}>
            <MDBInput
              wrapperClass="mb-4"
              label={
                inputData.planAmount > 0 ? "Address" : "Address (Optional)"
              }
              size="lg"
              name="address"
              type="text"
              maxLength={256}
              value={inputData.address}
              onChange={onChange}
              required={
                inputData.planAmount > 0 &&
                checkVisibility("address") !== "none"
                  ? true
                  : false
              }
            />
          </MDBCol>
          <div
            style={{ display: checkVisibility("mstatus") }}
            className="d-md-flex justify-content-start align-items-center mb-4"
          >
            <select
              onChange={(e) => {
                onMemberSelection(e.target.value);
              }}
              required={checkVisibility("mstatus") !== "none" ? true : false}
              name="status"
              style={{ display: checkVisibility("mstatus") }}
              value={inputData.status}
              className="form-select mb-3"
              aria-label=".form-select-lg example"
            >
              <option value="">Choose Member Status</option>

              {allStatus.map((item) => (
                <option value={item.id}>{item.statusName}</option>
              ))}
            </select>
          </div>
          <MDBCol style={{ display: checkVisibility("zipcode") }}>
            <MDBInput
              wrapperClass="mb-4"
              label="Zip Code"
              size="lg"
              name="zipCode"
              required={checkVisibility("zipcode") !== "none" ? true : false}
              type="text"
              maxLength={8}
              value={inputData.zipCode}
              onChange={onChange}
            />
          </MDBCol>

          <MDBCol style={{ display: checkVisibility("hsize") }}>
            <MDBInput
              wrapperClass="mb-4"
              label="Household Size"
              size="lg"
              name="householdSize"
              required={checkVisibility("hsize") !== "none" ? true : false}
              maxLength={5}
              value={inputData.householdSize}
              onChange={onChange}
              type="text"
            />
          </MDBCol>

          <MDBCol md="12" style={{ display: checkVisibility("comments") }}>
            <MDBTextArea
              wrapperClass="mb-4"
              value={inputData.comments}
              onChange={onChange}
              placeholder="Add your comments"
              id="textAreaExample"
              label="Comments (Optional)"
              rows={2}
              maxLength={500}
              name="comments"
            ></MDBTextArea>
          </MDBCol>

          <hr></hr>
          <div style={{ display: paymentDiv ? "block" : "none" }}>
            <MDBRow>
              <MDBCol md="6" style={{ display: checkVisibility("planAmount") }}>
                <MDBInput
                  wrapperClass="mb-4"
                  label="Total Due"
                  size="lg"
                  name="planAmount"
                  type="text"
                  readOnly={true}
                  className="col-md-6"
                  value={inputData.planAmount}
                  onChange={onChange}
                />
              </MDBCol>

              <MDBCol
                md="6"
                style={{ display: checkVisibility("remainingAccount") }}
              >
                <MDBInput
                  wrapperClass="mb-4"
                  label="Remaining Amount"
                  size="lg"
                  name="remainingAmount"
                  type="text"
                  readOnly={true}
                  className="col-md-6"
                  value={inputData.remainingAmount}
                  onChange={onChange}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" style={{ display: checkVisibility("paidAmount") }}>
                <MDBInput
                  wrapperClass="mb-4"
                  label="Paid Amount"
                  size="lg"
                  name="paidAmount"
                  type="number"
                  required={
                    inputData.planAmount > 0 &&
                    checkVisibility("paidAmount") !== "none"
                      ? true
                      : false
                  }
                  min={inputData.planAmount > 0 ? 1 : 0}
                  max={inputData.planAmount}
                  value={inputData.paidAmount}
                  onChange={(e) => onChangepaidAmount(e.target.value)}
                  autoFocus
                />
              </MDBCol>

              <MDBCol md="6" style={{ display: checkVisibility("gotra") }}>
                <MDBInput
                  wrapperClass="mb-4"
                  label="Gotra (Optional)"
                  size="lg"
                  name="gotra"
                  type="text"
                  maxLength={20}
                  value={inputData.gotra}
                  onChange={onChange}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol
                md={
                  inputData.planAmount > 0 && inputData.remainingAmount > 0
                    ? "6"
                    : "12"
                }
              >
                <div
                  style={{ display: checkVisibility("paymentType") }}
                  className="justify-content-start align-items-center mx-1 row mb-4"
                >
                  <label className="text-start mb-2">
                    <b>Select Payment Type</b>
                  </label>

                  <select
                    onChange={onChange}
                    required={
                      inputData.planAmount &&
                      checkVisibility("paymentType") !== "none"
                        ? true
                        : false
                    }
                    name="paymentType"
                    value={inputData.paymentType}
                    className="form-select mb-3"
                    aria-label=".form-select-lg example"
                  >
                    <option value="">Choose Payment Type</option>

                    <option value="Check">Check</option>
                    <option value="Cash">Cash</option>
                    <option value="Card">Card</option>
                    <option value="Zelle">Zelle</option>
                    <option value="Ach/Wire">Ach/Wire</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </MDBCol>
              <MDBCol
                style={{
                  display:
                    inputData.planAmount > 0 &&
                    inputData.remainingAmount > 0 &&
                    checkVisibility("paymentPlanName") !== "none"
                      ? "inline"
                      : "none",
                }}
                md="6"
              >
                <div className=" mx-1 ustify-content-start align-items-center mb-4 row">
                  <label className="text-start mb-2">
                    <b>Select Payment Plan</b>
                  </label>

                  <select
                    onChange={onChange}
                    required={
                      inputData.planAmount > 0 &&
                      inputData.remainingAmount > 0 &&
                      checkVisibility("paymentPlanName") !== "none"
                        ? true
                        : false
                    }
                    name="paymentPlan"
                    value={inputData.paymentPlan}
                    className="form-select mb-3"
                    aria-label=".form-select-lg example"
                  >
                    <option value="">Choose Payment Plan</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Quaterly">Quaterly</option>
                    <option value="Semi-Anually">Semi-Anually</option>
                    <option value="Anually">Anually</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="6" style={{ display: checkVisibility("leadName") }}>
                <MDBInput
                  wrapperClass="mb-4"
                  label="Contact Lead Name (Optional)"
                  size="lg"
                  name="leadName"
                  type="text"
                  maxLength={20}
                  className="col-md-6"
                  value={inputData.leadName}
                  onChange={onChange}
                />
              </MDBCol>
              <MDBCol
                md="6"
                style={{ display: checkVisibility("verificationCode") }}
              >
                <MDBInput
                  wrapperClass="mb-4"
                  label="Manager Verification Code"
                  size="lg"
                  name="mVCode"
                  type="text"
                  className="col-md-6"
                  maxLength={20}
                  required={
                    inputData.planAmount > 0 &&
                    checkVisibility("verificationCode") !== "none"
                      ? true
                      : false
                  }
                  value={inputData.mVCode}
                  onChange={onChange}
                />
              </MDBCol>
            </MDBRow>

            <div className="d-md-flex ustify-content-start align-items-center mb-4">
              <label>Do you want to add spouse?</label>
              <br></br>

              <MDBRadio
                className="mx-2"
                name="isSpouseAdded"
                id="yes"
                value="true"
                label="Yes"
                checked={inputData.isSpouseAdded==="true"?"checked":""}
                onChange={onChange}
                inline
              />
              <MDBRadio
                className="mx-2"
                name="isSpouseAdded"
                id="no"
                value="false"
                checked={inputData.isSpouseAdded==="false"?"checked":""}
                onChange={onChange}
                label="No"
                inline
              />
            </div>
            <MDBRow
              style={{
                display: inputData.isSpouseAdded === "true" ? "flex" : "none",
              }}
            >
              <MDBCol
                md="6"
                style={{ display: checkVisibility("spFirstName") }}
              >
                <MDBInput
                  wrapperClass="mb-4"
                  label="First Name"
                  size="lg"
                  name="spFirstName"
                  maxLength={20}
                  required={
                    inputData.isSpouseAdded === "true" &&
                    checkVisibility("spFirstName") !== "none"
                      ? true
                      : false
                  }
                  value={inputData.spFirstName}
                  onChange={onChange}
                  type="text"
                />
              </MDBCol>

              <MDBCol md="6" style={{ display: checkVisibility("spLastName") }}>
                <MDBInput
                  wrapperClass="mb-4"
                  label="Last Name"
                  size="lg"
                  name="spLastName"
                  type="text"
                  maxLength={20}
                  required={
                    inputData.isSpouseAdded === "true" &&
                    checkVisibility("spLastName") !== "none"
                      ? true
                      : false
                  }
                  value={inputData.spLastName}
                  onChange={onChange}
                />
              </MDBCol>
            </MDBRow>
          </div>

          <div className="justify-content-end pt-3">
            <MDBBtn className="ms-1" color="warning" size="m">
              Submit form
            </MDBBtn>
          </div>
        </MDBCardBody>
      </form>
    </div>
  );
}
