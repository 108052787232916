import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import Modal from "react-modal";
import LoadingSpinner from "../Spinner";
import { callAdminApi } from "../API/ApiInterface";
import { customStyles } from "../CustomStyle";
export default function Otp(props) {
  const initialState = {
    phoneNumber: "",
    countryCode: "1",
  };
  const [inputData, setInputData] = useState(initialState);
  const [regModal, setRegistrationModal] = useState(false);
  const [countModal, setCountModal] = useState(false);
  const [selectedCount, setSelectedCount] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [allCounts, setAllCounts] = useState([]);
  const [rData, setRData] = useState({});
  const [showCountryCode, setShowCountryCode] = useState(true);

  const phoneNumberOtp = useRef(null);
  const countryCodeRef = useRef(null);
  const otp = useRef(null);
 

  const isValidPhone = (p) => {
    var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    var digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
  };
  const onSubmitCount = (e) => {
    e.preventDefault();

    rData.selectedCount = selectedCount;
    rData.scanType = 2;

    props.displayScannedInfo(rData);
    setCountModal(false);
  };
  const handlePhoneSubmit = (e) => {
    const phoneNumber = phoneNumberOtp.current.value;
    const countryCode = countryCodeRef.current.value;

    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();
    fetch(process.env.REACT_APP_BASEURL + "/users/checkUserDetail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
      body: JSON.stringify({ phoneNumber, countryCode }),
    })
      .then(async (result) => {
        const data = await result.json();
        if (data.code === 200) {
          setInputData(initialState);
          const rData = data.body;

          let difference =
            parseInt(rData.householdSize) - parseInt(rData.confirmationCount);

          console.log(`difference\n\n${difference}`);
          if (difference === 0) {
            toast.error(
              "This user has exceeded the verification limit; please wait 24 hours to reactivate the count",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          } else {
            setCountModal(true);
            const newarray = [];
            for (let t = 0; t < difference; t++) {
              newarray.push(t + 1);
            }

            setAllCounts(newarray);
            setRData({
              id: rData.id,
              name: rData.firstName + "" + rData.lastName,
              contact: rData.phoneNumber,
              email: rData.email,
              zipCode: rData.zipCode,
              address: rData.address,
              scantype: 2,
              count: selectedCount,
            });
          }
        } else if (data.code === 201) {
          if (isValidPhone(phoneNumber)) {
            setRegistrationModal(true);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const sendRegistrationLink = (e) => {
    const phoneNumber = phoneNumberOtp.current.value;
    const countryCode = countryCodeRef.current.value;
    setLoading(true);
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();

    setRegistrationModal(false);
    setInputData(initialState);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/scan/sendReglink",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({ phoneNumber, countryCode }),
      }
    )
      .then(async (data) => {
        setLoading(false);
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          otp.current.value = "";
          setRegistrationModal(false);
          setInputData(initialState);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const onChange = (e) => {
    if (e.target.name === "phoneNumber") {
      if (e.target.value === "") setShowCountryCode(true);
      else {
        if (!isValidPhone(e.target.value)) setShowCountryCode(false);
        else setShowCountryCode(true);
      }
    }

    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <div className="col-md-7  ">
        <form autoComplete="off" onSubmit={handlePhoneSubmit} className="p-2">
          <div className="mb-3">
            <div>
              <b className="fs-5">Enter your phone number or unique code for verification </b>
            </div>

            <div class="row mt-5 mb-4 justify-content-center">
              <div
                class="col-4"
                style={{ display: showCountryCode ? "" : "none" }}
              >
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label "
                >
                  Country Code
                </label>
                <input
                  type="text"
                  ref={countryCodeRef}
                  className="form-control text-center fs-4 "
                  id="countryCode"
                  name="countryCode"
                  placeholder="(1)"
                  maxLength={3}
                  value={inputData.countryCode}
                  onChange={onChange}
                  required
                />{" "}
              </div>
              <div class="col-8">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                >
                  Phone Number or Unique Code
                </label>
                <input
                  type="text"
                  ref={phoneNumberOtp}
                  className="form-control fs-4"
                  id="exampleInputEmail1"
                  name="phoneNumber"
                  autoFocus={true}
                  value={inputData.phoneNumber}
                  maxLength={11}
                  onChange={onChange}
                  required
                />{" "}
              </div>
            </div>

            {/* <small className="text-danger mt-5">
              Note: Country code is required only when you enter phone number
            </small> */}

            <small className="text-secondary mt-5">
              By providing my phone number, I agree to receive text message from
              {process.env.REACT_APP_NAME}. Standard message and data rates
              apply
            </small>
          </div>

          <div className="mt-3 mb-5">
            <button
              className="btn btn-success  mt-2 px-4 verify-btn"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>

        <Modal
          isOpen={regModal}
          style={customStyles}
          appElement={document.getElementById("root")}
        >
          <button
            className="close-button"
            onClick={(e) => setRegistrationModal(false)}
          >
            ×
          </button>

          <form autoComplete="off" onSubmit={sendRegistrationLink} className="p-2">
            <div className="mb-3">
              <div>
                <b className="fs-5">
                  This phone number is not register with us.We will send you a
                  registartion link to your number (
                  {"+" +
                    countryCodeRef?.current?.value +
                    "-" +
                    phoneNumberOtp?.current?.value}
                  )
                </b>
              </div>

              <label htmlFor="exampleInputEmail1" className="fs-5 mt-4">
                Is this a correct number?
              </label>
              <br></br>
            </div>

            <button type="submit" className="btn btn-primary mt-4 ">
              Yes
            </button>

            <button
              type="button"
              onClick={(e) => setRegistrationModal(false)}
              className="btn btn-secondary mx-2 mt-4 "
            >
              No
            </button>
          </form>
        </Modal>

        <Modal
          isOpen={countModal}
          style={customStyles}
          appElement={document.getElementById("root")}
        >
          <button
            className="close-button"
            onClick={(e) => setCountModal(false)}
          >
            ×
          </button>

          <form autoComplete="off" onSubmit={onSubmitCount} className="p-2">
            <div className="mb-3">
              <div>
                <b className="fs-5">Select house hold size</b>
              </div>
              <div className="col-md-3">
                <select
                  onChange={(e) => setSelectedCount(e.target.value)}
                  className="form-select select2 mt-2 p-2"
                  aria-label=".form-select-lg example"
                >
                  {allCounts.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <br></br>
            </div>

            <button type="submit" className="btn btn-primary mt-4 ">
              Submit
            </button>

            <button
              type="button"
              onClick={(e) => setCountModal(false)}
              className="btn btn-secondary mx-2 mt-4 "
            >
              Cancel
            </button>
          </form>
        </Modal>
      </div>
    </>
  );
}
