import React from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";

export default function PaymentInfo({ paymentDetail,userDetail }) {
  return (
    <div
      id="customers"
      className="infowindow m-3 p-2 overflow-auto"
      style={{ height: "500px" }}
    >
      <table>
        <thead>
          <tr></tr>
        </thead>
        <tbody className="fs-5">
        <tr>
              <td style={{"backgroundColor":"#5aa788"}}>DOB</td>
              <td>{userDetail?.dob}</td>
            </tr>
            <tr>
              <td style={{"backgroundColor":"#5aa788"}}>Plan Amount</td>
              <td>{paymentDetail?.totalAmount}</td>
            </tr>
            <tr>
              <td style={{"backgroundColor":"#5aa788"}}>Paid Amount</td>
              <td>{paymentDetail?.paidAmount}</td>
            </tr>
            <tr>
              <td style={{"backgroundColor":"#5aa788"}}>Remaining Amount</td>
              <td>{paymentDetail?.remainingAmount}</td>
            </tr>
            <tr>
              <td style={{"backgroundColor":"#5aa788"}}>Payment Plan Name</td>
              <td>{paymentDetail?.paymentPlanName}</td>
            </tr>
            <tr>
              <td style={{"backgroundColor":"#5aa788"}}>Payment Type</td>
              <td>{paymentDetail?.paymentType}</td>
            </tr>
            <tr>
              <td style={{"backgroundColor":"#5aa788"}}>Lead Name</td>
              <td>{paymentDetail?.leadName}</td>
            </tr>
            <tr>
              <td style={{"backgroundColor":"#5aa788"}}>Verification Code</td>
              <td>{paymentDetail?.verificationCode}</td>
            </tr>
            <tr>
              <td style={{"backgroundColor":"#5aa788"}}>Spouse Name</td>
              <td>
                {paymentDetail.spouseInfo? paymentDetail.spouseInfo?.firstName +
                  " " +
                  paymentDetail.spouseInfo?.lastName:"NA"}
              </td>
            </tr>
            <tr>
              <td style={{"backgroundColor":"#5aa788"}}>Spouse Dob</td>
              <td>{paymentDetail.spouseInfo?.dob?paymentDetail.spouseInfo?.dob:"NA"}</td>
            </tr>
        </tbody>
      </table>
    </div>
  );
}
