import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";

export const searchZipCode = async (zipCode) => {
  return fetch(
    process.env.REACT_APP_BASEURL + "/zipcodes/search?zipCode=" + zipCode,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
    }
  );
};

export const getAllLeads = async () => {
  return fetch(process.env.REACT_APP_BASEURL + "/users/getFilterInfo?admin=1", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });
};

export const getAllStatus = async (admin=0) => {
  return fetch(process.env.REACT_APP_BASEURL + "/users/getStatus?admin="+admin, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });
};

export const callAdminApi = (url, method, content) => {
  const headers = {
    "access-control-allow-origin": "*",
  };

  if (content.type === "json") {
    headers["Content-Type"] = "application/json";
  }

  headers.Authorization = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method || "GET",
      body: content.body ? content.body : null,
      // mode: "cors",
      headers: headers,
    })
      .then(async (response) => {
        const dataP = await response.json();

        // response only can be ok in range of 2XX
        if (response.ok) {
          // you can call response.json() here too if you want to return json
          resolve(dataP);
        } else {
          //handle errors in the way you want to
          switch (response.status) {
            case 401:
              console.log("Unauthorized");

              break;

            case 403:
              swal("Oops!", dataP.message, "error");

              break;
            default:
              resolve(dataP);
              break;
          }

          //here you also can thorow custom error too
          reject(dataP);
        }
      })
      .catch((error) => {
        //it will be invoked mostly for network errors
        //do what ever you want to do with error here
        console.log(error);
        reject(error);
      });
  });
};

export const useSecureFetch = (url, method, content) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [result, setResultD] = useState(null);
  const [loading, setLoading] = useState(null);

  const fetchApi = async () => {
    setLoading(true);

    const headers = {
      "Content-Type":
        content.type === "json" ? "application/json" : "multipart/form-data",
      "access-control-allow-origin": "*",
    };

    if (content.auth) {
      headers.Authorization = localStorage.getItem("token");
    }

    await fetch(url, {
      method: method || "GET",
      body: content.data ? JSON.stringify(content.data) : "",
      // mode: "cors",
      headers: headers,
    })
      .then((ret) => {
        setLoading(false);
        if (ret.status === 401) {
          return navigate("/admin/login");
        }
        if (ret.status > 401 && ret.status < 600) {
          throw new Error("Bad response from server");
        }
        return ret;
      })
      .then(async (response) => {
        // response only can be ok in range of 2XX
        if (response.ok) {
          const dataP = await response.json();
          setResultD(dataP);
        } else {
          console.log(response);

          //handle errors in the way you want to
          switch (response.status) {
            case 404:
              console.log("Object not found");
              break;
            case 500:
              console.log("Internal server error");
              break;

            case 401:
              
                toast.error("Please login to proceed", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setTimeout(() => {
                  return navigate("/admin/login");
                }, 1000);
              
              break;
            default:
              console.log("Some error occured");
              break;
          }
          setError(response);

          //here you also can thorow custom error too
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  useEffect(() => {
    if (content.data !== "") {
      fetchApi();
    }
  }, []);

  return { result, error, loading, fetchApi };
};
