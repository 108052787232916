import React, { useContext, useEffect, useState } from "react";
import { callAdminApi } from "../../API/ApiInterface";
import LoadingSpinner from "../../Spinner";
import { AppContext } from "../../AppContext";
import { toast } from "react-toastify";

export default function Permissions({ adminId }) {
  const [isLoading, setLoading] = useState(false);
  const { allModules, setUserUpdated } = useContext(AppContext);
  const [permissionList, updateCheckedPerm] = useState([]);

  const removeDuplicate = (data) => {
    const uniqueAuthors = data.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    return uniqueAuthors;
  };
  useEffect(() => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/fetchPermissions/" + adminId,
      "GET",
      { type: "json", body: null, auth: true }
    )
      .then(async (data) => {
        setLoading(false);
        if (data.code === 200) {
          //setData(data.body);

          const array = [];
          data.body?.map((item) => {
            return array.push({
              id: item.moduleId,
              ids: item.permissions,
            });
          });

          updateCheckedPerm([...removeDuplicate(array)]);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  }, []);

  const updatePermissionApi = () => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/updatePermissions",
      "POST",
      {
        type: "json",
        body: JSON.stringify({ permissions: permissionList, adminId: adminId }),
        auth: true,
      }
    )
      .then(async (data) => {
        setLoading(false);
        setUserUpdated(true);
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
      });
  };

  const updatePermission = (checked, moduleId, id) => {
    if (id === 0) {
      if (checked) permissionList.push({ id: moduleId, ids: [1, 2, 3, 4] });
      else {
        var index = permissionList.findIndex((ip) => ip.id === moduleId);
        if (index !== -1) {
          permissionList.splice(index, 1);
        }
      }
    } else {
      const findIndex = permissionList.findIndex(
        (item) => item.id === moduleId
      );
      const findItem = permissionList.find((item) => item.id === moduleId);

      const parray = findIndex >= 0 ? findItem?.ids : [];
      if (findIndex >= 0) {
        if (checked) {
          parray.push(id);
        } else {
          var indexFind = parray.indexOf(Number(id));
          if (indexFind !== -1) {
            parray.splice(indexFind, 1);
          }

        }
        permissionList[findIndex] = { id: moduleId, ids: parray };

        if(parray.length===0)permissionList.splice(findIndex, 1);
      } else {
        permissionList[0] = { id: moduleId, ids: [id] };
      }
    }

    const newArray = removeDuplicate(permissionList);
    updateCheckedPerm([...newArray]);
  };

  const checkedData = (moduleId, submodule) => {
    let checked = "";
    const findIndex = permissionList.findIndex((it) => it.id === moduleId);

    if (findIndex < 0) return "";
    if (submodule === 0) {
      checked = "checked";
    } else {
      const array = permissionList[findIndex].ids;
      if (array.includes(submodule)) checked = "checked";
      else checked = "";
    }
    return checked;
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <div
        id="customers"
        className="infowindow overflow-auto "
        style={{ height: "600px" }}
      >
        {allModules.map((item, index) => (
          <div className="col-md-12 mt-4">
            <input
              checked={checkedData(item.moduleId, 0)}
              onChange={(e) =>
                updatePermission(e.target.checked, item.moduleId, 0)
              }
              id={item.moduleId}
              className="form-check-input  mx-1 mt-1"
              type="checkbox"
            />
            <span className="fs-5 mx-1"><b>{item.module?.moduleName}</b></span>
            <br></br>
            <div className="mt-2">
              <input
                className="form-check-input mx-4 "
                onChange={(e) =>
                  updatePermission(e.target.checked, item.moduleId, 1)
                }
                checked={checkedData(item.moduleId, 1)}
                type="checkbox"
              />
              Create
              <input
                className="form-check-input mx-4 "
                onChange={(e) =>
                  updatePermission(e.target.checked, item.moduleId, 2)
                }
                checked={checkedData(item.moduleId, 2)}
                type="checkbox"
              />
              Read
              <input
                className="form-check-input mx-4"
                onChange={(e) =>
                  updatePermission(e.target.checked, item.moduleId, 3)
                }
                type="checkbox"
                checked={checkedData(item.moduleId, 3)}
              />
              Update
              <input
                className="form-check-input mx-4"
                onChange={(e) =>
                  updatePermission(e.target.checked, item.moduleId, 4)
                }
                checked={checkedData(item.moduleId, 4)}
                type="checkbox"
              />
              <span>Delete</span>
            </div>
          </div>
        ))}
        <button onClick={updatePermissionApi} className="btn btn-success mt-5">
          {" "}
          Update Permissions
        </button>
      </div>
    </>
  );
}
