import React, { useEffect, useState, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { callAdminApi } from "../API/ApiInterface";
import LoadingSpinner from "../Spinner";
import Modal from "react-modal";
import Search from "../users/Search";
import { AppContext } from "../AppContext";
import { customStyles } from "../CustomStyle";
export default function PublicControl() {
  const [allOptions, setAllOptions] = useState([]);
  const [leadDeleted, setLeadDeleted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const { leadsUpdate } = useContext(AppContext);


  function closeModal() {
    setIsOpen(false);
  }

  const restrictLeads = (status, id) => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/publicInfo/changeStatus",
      "POST",
      {
        auth: true,
        type: "json",
        body: JSON.stringify({
          status: status,
          id: id,
        }),
        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          getFilterInfo();
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        toast.success(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getFilterInfo = useCallback(() => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL +
        "/admin/publicInfo/getPublicInfoOptions?admin=1",
      "GET",
      {
        type: "json",
        body: null,
        auth: true,
      }
    )
      .then(async (data) => {
        setLoading(false);

        if (data.code === 200) {
          const filters = data.body;

          const array = [];

          filters?.map((item) => {
            return array.push(item);
          });
          setAllOptions([...array]);
          setLeadDeleted(false);
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  }, []);

  useEffect(() => {
    getFilterInfo();
    closeModal();
  }, [leadDeleted, leadsUpdate]);

  useEffect(() => {
    getFilterInfo();
  }, []);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="bodyNav container">
        <div className="col-md-12 row my-3">
          <div
            style={{ margin: "0 auto" }}
            className="col-md-4 text-center border "
          >
            <h3 className="mt-3 justify-content-center">Public Information Control</h3>
            <hr></hr>
            <div
              id="customers"
              className="infowindow row justify-content-center m-3 p-2 overflow-auto"
              style={{ height: "500px" }}
            >
              <table>
                <thead>
                  <tr  className="text-center mx-2"
>
                    <th><span className="mx-5">Public</span></th>
                    <th><span className="mx-5">Option Name</span></th>
                  </tr>
                </thead>
                <tbody>
                  {allOptions.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={item.public === 1 ? "checked" : ""}
                          onChange={(e) => {
                            restrictLeads(e.target.checked, item.id);
                          }}
                          name="selectedIds"
                        ></input>
                      </td>
                      <td>{item.option}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <button onClick={closeModal}>close</button>

          <Search requestType="admin" pageType="admin" zipCode="0"></Search>
        </Modal>
      </div>
    </>
  );
}
