import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { MDBCheckbox } from "mdb-react-ui-kit";
import Modal from "react-modal";
import { callAdminApi, searchZipCode } from "../API/ApiInterface";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { toast } from "react-toastify";
import { customStyles } from "../CustomStyle";
// import states from "./data.json";
import { GoogleApiWrapper } from "google-maps-react-18-support";
import Accordion from "react-bootstrap/Accordion";
import Search from "../users/Search";
import { AppContext } from "../AppContext";
export function AdminHome({ google }) {
  const mapRef = useRef(null);
  const [storedMap, setMap] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [viewZipcode, setCurrentViewCode] = useState(0);
  const { leadsUpdate, setLeadsUpdate } = useContext(AppContext);
  const [infoWRecords, setAllInfoWindo] = useState([]);
  const [infoWindow, setInfowindow] = useState(null);
  const [totalUsersLead, updateTotalUsers] = useState(0);
  const searchRef = useRef(null);
  const [gmarkers, setGMarkers] = useState([]);
  const [search, setSearch] = useState("");
  const [getTimeData, setFirstTimeData] = useState(1);

  let map;

  const [selctedIds, setCheckValue] = useState([]);
  const [statusInfo, setStatusInfo] = useState([]);

  const [selectedNesteds, setNestedCheckValue] = useState([]);
  const [allZipCodes, setAllZipCodes] = useState([]);

  const [filterInfo, setFilterinfo] = useState(null);

  const effectRan = useRef(false);

  // useEffect(()=>{
  //   openModal()
  //   getStatusData("76951")
  // },[])

  const getFilterData = useCallback(() => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/users/getFilterData",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({
          leads: selctedIds,
          zipCodes: selectedNesteds.map((ids) => ids.id),
        }),
        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        setFirstTimeData(2);

        if (data.code === 200) {
          const result = data.body;

          const allzips = [];

          result?.map((item) => {
            return allzips.push(item);
          });

          setAllZipCodes([...allzips.flat()]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selctedIds, selectedNesteds]);

  const getFilterInfo = useCallback(() => {
    fetch(process.env.REACT_APP_BASEURL + "/users/getFilterInfo?admin=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
    }).then(async (result) => {
      const data = await result.json();
      setLeadsUpdate(false);

      if (data.code === 200) {
        const filters = data.body;

        setFilterinfo(filters);
        setCheckValue(filters?.leadInfo.map((item) => item.id));
        const array = [];
        let sum = 0;
        filters?.leadInfo.map((item) => {
          sum = sum + item.totalSum;
          return item?.zipcodes?.map((innerItem) => {
            return array.push({ id: innerItem.id, parent: item.id });
          });
        });

        setNestedCheckValue(array);
        updateTotalUsers(sum);
      }
    });
  }, [leadsUpdate]);

  useEffect(() => {
    if (effectRan.current || process.env.NODE_ENV !== "development") {
      getFilterInfo();
    }

    return () => (effectRan.current = true);
  }, []);

  useEffect(() => {
    if (leadsUpdate) {
      infoWRecords.map((iW) => {
        if (iW != null) iW.close();
        return iW;
      });
      setIsOpen(false);
      setTimeout(() => {
        getFilterData();
        getFilterInfo();
      }, 500);
    }
  }, [leadsUpdate]);

  useEffect(() => {
    if (search !== "") {
      searchZipCode(search)
        .then(async (result) => {
          const data = await result.json();
          if (data.code === 200) {
            const latitude = data.body.latitude;
            const longitude = data.body.longitude;
            // storedMap.setCenter(new window.google.maps.LatLng(Number(latitude),Number(longitude)));
            const location = new window.google.maps.LatLng(
              Number(latitude),
              Number(longitude)
            );
            const myLatLng = { lat: Number(latitude), lng: Number(longitude) };

            storedMap.panTo(location);
            storedMap.setZoom(10);
            const marker = new window.google.maps.Marker({
              position: myLatLng,
              title: "Hello World!",
            });

            for (let i = 0; i < gmarkers.length; i++) {
              gmarkers[i].setMap(null);
            }

            marker.setMap(storedMap);
            gmarkers.push(marker);
            setGMarkers([...gmarkers]);
          } else {
            toast.error("No result", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => console.log(e));
    }
  }, [search]);
  useEffect(() => {
    setInfowindow(null);
    const drawRegionsMap = () => {
      if (mapRef.current) {
        if (!storedMap) {
          map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 31.0, lng: -100.0 },
            // center: { lat: 40.76, lng: -101.64 },
            zoom: 7,
            // In the cloud console, configure this Map ID with a style that enables the
            // "Administrative Area Level 1" feature layer.
            mapId: "cea5f368e232b458",
          });
        } else {
          map = storedMap;
        }
        const featureStyleOptions = {
          strokeColor: "#810FCB",
          strokeOpacity: 1.0,
          strokeWeight: 3.0,
          // fillColor: "#ddd2d1",
          fillOpacity: 0.5,
        };
        const featureLayerBoundary = map.getFeatureLayer(
          window.google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1
        );

        if (featureLayerBoundary) {
          featureLayerBoundary.style = (featureStyleFunctionOptions) => {
            const placeId = featureStyleFunctionOptions.feature.placeId;
            if (placeId === "ChIJSTKCCzZwQIYRPN4IGI8c6xY")
              return featureStyleOptions;
          };

          const featureLayerZipCode = map.getFeatureLayer(
            window.google.maps.FeatureType.POSTAL_CODE
          );

          featureLayerZipCode.style = (featureStyleFunctionOptions) => {
            const placeId = featureStyleFunctionOptions.feature.placeId;

            let obj = allZipCodes.find((o) => o.placeId === placeId);
            let color = "#052c65";

            if (obj) {
              color = obj.lead ? obj.lead?.color : "";
              return {
                fillColor: color,
                fillOpacity: 0.8,
                strokeColor: "#6c6767",
                strokeOpacity: 10.5,
                strokeWeight: 1.0,
              };
            }
          };

          setMap(map);
          setInfowindow(new window.google.maps.InfoWindow());
          infoWRecords.push(infoWindow);
          setAllInfoWindo([...infoWRecords]);
          google.maps.event.clearListeners(featureLayerZipCode, "click");

          // featureLayerZipCode.addListener("click",handleClick)
          featureLayerZipCode.addListener("click", function (e) {
            createInfoWindow(e);
          });
        }
        // Population data by state.
      }
    };

    drawRegionsMap();
  }, [allZipCodes]);

  useEffect(() => {
    if (
      (selctedIds.length > 0 && selectedNesteds.length > 0) ||
      getTimeData > 1
    ) {
      getFilterData();
    }
  }, [selctedIds, selectedNesteds]);

  useEffect(() => {}, [statusInfo]);

  function openModal() {
    setIsOpen(true);
    setLeadsUpdate(false);
  }

  function closeModal() {
    setIsOpen(false);
  }

  // const viewMember=(id)=>{
  //   alert(id)
  // }

  // const getCheckedDetail = (levelName, id, parentId) => {
  //   if (levelName == "child") {
  //     if (selctedIds.includes(parentId)) return true;
  //     else return false;
  //   }
  // };

  function handleAssignment(zipCode, placeId) {
    if (document) {
      // const dataM = document.querySelector(
      //   "input[name=assignLead]:checked"
      // ).value;

      const dataM = document.getElementById("assignLead").value;
      updateLead(zipCode, dataM, placeId);
      infoWindow.close();
    }

    // const dataVal=$('input[name="assignLead"]:checked').val();

    //alert(e.target.id)
  }

  const updateInfoWindow = (content, center) => {
    infoWindow.setContent(content);
    infoWindow.setPosition(center);

    infoWindow.open({
      map,
      shouldFocus: false,
    });
  };

  const createInfoWindow = async (event) => {
    let feature = event.features[0];

    if (!feature.placeId) return;

    // Update the infowindow.
    const place = await feature.fetchPlace();

    await getStatusData(place.displayName)
      .then(async (result) => {
        const data = await result.json();

        if (data.code === 200) {
          setStatusInfo([...data.body]);

          let obj = allZipCodes.find((o) => o.placeId === feature.placeId);

          let content = `<div id="customers" class="infowindow text-start  fs-6"><span style="font-size:medium">
        <table>
        <th>Heading</th>
        <th>Info</th>
        <tr>
        <td>Zip Code</td>
        <td>${place.displayName}</td>
        </tr>
        <tr>
        <td>Lead Name</td>
        <td>${obj?.lead ? obj.lead?.name : "Not Assigned"}</td>
        </tr>

        <tr>
        <td>Total Count</td>
        <td>${obj?.totalCount ? obj.totalCount : 0}</td>
        </tr>

        <tr>
        <td>User Status</td>
        <td>
        <div class="mb-3">${data.body
          ?.map(
            (item) =>
              `<p class="m-0">${item.statusName} : ${item.users?.length}</p>`
          )
          .join("<br>")}</div>


        <a href="javascript:void(0)" id="viewUserList"  >View All</a>
    </td>
        </tr>

        <tr>
        <td>Assign to</td>
        <td>
        <select
        id="assignLead"
        className="form-select form-select-lg mb-3"
        aria-label=".form-select-lg example"
      >

        ${filterInfo?.leadInfo.map(
          (item) =>
            `<option  id="${item.id}"  value="${item.id}"  ${
              item.id === obj?.leadId ? "selected" : ""
            }>${item.name}</option>`
        )}
        </select>
            <button id="btnAssigned" >Assign</button>

        </tr>

        </table>
        </div>`;

          google.maps.event.addListener(infoWindow, "domready", function () {
            document.getElementById("btnAssigned").onclick = function () {
              handleAssignment(place.displayName, feature.placeId, data.body);
            };
            document.getElementById("viewUserList").onclick = function () {
              setStatusInfo([...data.body]);
              setCurrentViewCode(place.displayName);
              openModal();
            };

            //
          });

          updateInfoWindow(content, event.latLng);
        }
      })
      .catch((err) => console.log(err));
  };

  const updateLead = (zipCode, leadId, placeId) => {
    callAdminApi(process.env.REACT_APP_BASEURL + "/leads/updateLead", "POST", {
      type: "json",
      auth: true,
      body: JSON.stringify({
        zipCode: zipCode,
        leadId: leadId,
        placeId,
      }),
      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (data) => {
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setLeadsUpdate(true);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const getStatusData = async (zipCode) => {
    return fetch(
      process.env.REACT_APP_BASEURL +
        "/users/getMapStatusData?admin=1&zipCode=" +
        zipCode,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },

        // body:JSON.stringify({leads:selctedIds})
      }
    );
  };

  const setCheckedValue = (checked, levelName, id, parentId = 0) => {
    if (levelName === "child" && selctedIds.includes(parentId)) {
      if (checked) {
        selectedNesteds.push({ id: id, parent: parentId });
        setNestedCheckValue([...selectedNesteds]);
      } else {
        // var index = selectedNesteds.indexOf(Number(id));
        // if (index !== -1) {
        //   selectedNesteds.splice(index, 1);
        // }

        const newArray = selectedNesteds.filter(function (obj) {
          return obj.id !== id;
        });
        setNestedCheckValue([...newArray]);
      }
    } else {
      if (levelName === "lead" && checked) {
        selctedIds.push(Number(id));
      }

      if (levelName === "lead" && !checked) {
        //selctedIds.pu(id)
        var index = selctedIds.indexOf(Number(id));

        // alert(index)
        if (index !== -1) {
          selctedIds.splice(index, 1);
        }
      }

      const newArr = [...selctedIds];

      setCheckValue(newArr);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        appElement={document.getElementById("root")}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <button onClick={closeModal}>close</button>

        <Search
          pageType="admin"
          requestType="admin"
          zipCode={viewZipcode}
        ></Search>
      </Modal>

      <div className="bodyNav row">
        <div className="col-md-7 border row mx-2 my-3">
          <div
            style={{
              border: `2px`,
              height: "700px",
              width: "1000px",
              borderStyle: "groove",
            }}
            ref={mapRef}
            className="google-map"
          ></div>
        </div>
        <div className="col-md-4 border row mx-2  my-3">
          <div>
            <input
              type="text"
              ref={searchRef}
              placeholder="Search by Zip Code"
              className=" mt-2 form-control mt-2 p-2"
            />
            <button
              onClick={(e) => setSearch(searchRef.current.value)}
              className="btn  col-lg-12 btn-success btn-sm"
            >
              Search
            </button>
            <Accordion className="my-4" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span className="fs-5">
                    Leads Control &nbsp;(Total Leads:{" "}
                    {filterInfo?.leadInfo.length} , Total users:{" "}
                    {totalUsersLead})
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {filterInfo &&
                    filterInfo?.leadInfo.map((item) => (
                      <div key={item.id}>
                        <Accordion defaultActiveKey="1">
                          <Accordion.Item eventKey={item.id}>
                            {/* {alert(selctedIds.includes(item.id))} */}
                            <Accordion.Header>
                              <MDBCheckbox
                                id={item.id}
                                label={item.name + " - " + item.totalSum}
                                checked={
                                  selctedIds.includes(item.id) ? "checked" : ""
                                }
                                onChange={(e) =>
                                  setCheckedValue(
                                    e.target.checked,
                                    "lead",
                                    `${item.id}`
                                  )
                                }
                              />
                            </Accordion.Header>
                            <Accordion.Body style={{ paddingTop: "1px" }}>
                              {item?.zipcodes?.map((innerItem) => (
                                <div
                                  key={"leadsChild_" + innerItem.id}
                                  className="listItem"
                                >
                                  <MDBCheckbox
                                    id={innerItem.id}
                                    className="mt-2"
                                    checked={
                                      selctedIds.includes(item.id) &&
                                      selectedNesteds.find(
                                        (item) => item.id === innerItem.id
                                      )
                                        ? "checked"
                                        : ""
                                    }
                                    onChange={(e) =>
                                      setCheckedValue(
                                        e.target.checked,
                                        "child",
                                        innerItem.id,
                                        item.id
                                      )
                                    }
                                    inline
                                  />
                                  <p className="mt-2">{innerItem.zipCode}</p>
                                  <p className="mx-3">
                                    Total Users: {innerItem.totalCount}{" "}
                                    <button
                                      style={{
                                        display:
                                          innerItem.totalCount > 0
                                            ? "inline"
                                            : "none",
                                      }}
                                      className="btn mx-2 btn-warning btn-xs"
                                      onClick={() => {
                                        setCurrentViewCode(innerItem.zipCode);
                                        openModal();
                                      }}
                                    >
                                      View All
                                    </button>
                                  </p>
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ))}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <span className="fs-5">
                    Geo Control (Total County: {filterInfo?.countyInfo.length})
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {filterInfo &&
                    filterInfo?.countyInfo.map((item) => (
                      <div>
                        <Accordion
                          id={"county_" + item.id}
                          defaultActiveKey="county_1"
                        >
                          <Accordion.Item eventKey={"county_" + item.id}>
                            <Accordion.Header>
                              <p id={item.id}>
                                {" "}
                                {item.county ? item.county : "NA"}
                              </p>

                              <button
                                style={{
                                  display:
                                    item.totalCount > 0 ? "inline" : "none",
                                }}
                                className="btn mx-4 mb-3 btn-info btn-xs"
                                onClick={() => {
                                  setCurrentViewCode(
                                    item?.allZipCodes?.split(",")
                                  );
                                  openModal();
                                }}
                              >
                                {item.totalCount}-Users
                              </button>
                            </Accordion.Header>
                            {/* <Accordion.Body>
                            {item?.allZipCodes?.split(",").map((innerItem) => (
                              <div className="listItem">
                                <p>{innerItem}</p>
                              </div>
                            ))}
                          </Accordion.Body> */}
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAPKEY,
})(AdminHome);
