import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { callAdminApi } from "../../API/ApiInterface";
import LoadingSpinner from "../../Spinner";
import { AppContext } from "../../AppContext";

export default function EditTemplate({ statusDetail }) {
  const [isLoading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({
    name: statusDetail?.statusName,
    planAmount: statusDetail.planAmount,
    id: statusDetail.id,
  });
  const { setUserUpdated } = useContext(AppContext);

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleStatusUpdateSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();
    setLoading(true);
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/status/edit", "POST", {
      type: "json",
      auth: true,
      body: JSON.stringify({
        name: inputData.name,
        planAmount: inputData.planAmount,
        id: inputData.id,
      }),
      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (data) => {
        setLoading(false);

        if (data.code === 200) {
          setUserUpdated(true);
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.success(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="container row">
        <form autoComplete="off" onSubmit={handleStatusUpdateSubmit} className="p-2">
          <div className="mb-3 mt-3 ">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Status Name
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="name"
              maxLength={30}
              onChange={onChange}
              value={inputData.name}
              aria-describedby="emailHelp"
              required
            />
            <label htmlFor="exampleInputEmail1" className="form-label mt-2 ">
              Plan Amount
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleInputEmail1"
              name="planAmount"
              max={50000}
              min={0}
              value={inputData.planAmount}
              onChange={onChange}
              required
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary mt-4 "
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}
