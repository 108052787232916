import { toast } from "react-toastify";
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "./AppContext";
import { callAdminApi } from "./API/ApiInterface";

export default function List({ contacts, pageType, requestType }) {
  const initialState = {
    username: "",
    phoneNumber: "",
    countryCode: "1",
  };
  const [formValues, setFormValues] = useState(initialState);
  const [showForm, setShowForm] = useState(false);
  const [allUsers, setAllusers] = useState([]);

  const { setLeadsUpdate,publicInfo } = useContext(AppContext);

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
      removeRestrictedItems()
    
  }, [contacts]);





  const removeRestrictedItems = () => {
    if (requestType && requestType === "user") {


    contacts.map((item) => {
      if (!checkItemsInPublic("email")) {
        item.email = "";
      }
      if (!checkItemsInPublic("name")) {
        item.firstName = "";
      }

      if (!checkItemsInPublic("phoneNumber")) {
        item.phoneNumber = "";
      }

      if (!checkItemsInPublic("zipcode")) {
        item.zipCode = "";
      }

      if (!checkItemsInPublic("memberStatus")) {
        item.mapStatusInfo.statusName = "";
      }

      return item;
    });

  }
    setAllusers(contacts)

  };
  const checkItemsInPublic = (code) => {

    if (requestType === "admin") return true;
    const findItem = publicInfo.find(
      (item) => item.code === code && item.public === 1
    );
    if (findItem) return true;
    else return false;
  };

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();
    makeLead("", formValues.username, formValues.phoneNumber);
  };
  const makeLead = (userId, username = "", phoneNumber = "") => {
    callAdminApi(process.env.REACT_APP_BASEURL + "/leads/makeLead", "POST", {
      type: "json",
      auth: true,
      body: JSON.stringify({
        userId: userId,
        username: username,
        phoneNumber,
      }),
      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (data) => {
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setFormValues(initialState);
          setLeadsUpdate(true);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        toast.success(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div className="row d-flex">
      <button
        onClick={(e) => setShowForm(!showForm)}
        style={{
          float: "right",
          display:
            contacts.length > 0 || pageType === "viewAll" ? "none" : "inline",
        }}
        className="btn btn-primary"
      >
        Create Lead
      </button>

      <div
        id="customers"
        className="infowindow overflow-auto"
        style={{ height: "400px" }}
      >
        <div
          style={{
            display: showForm && contacts.length === 0 ? "inline" : "none",
          }}
          className="border"
        >
          <form  autoComplete="off" onSubmit={handleSubmit} className="p-2">
            <h5> Enter Detail to Create Lead</h5>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                name="username"
                maxLength={30}
                onChange={onChange}
                aria-describedby="emailHelp"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Phone Number (Country code included)
              </label>
              <input
                type="text"
                maxLength={15}
                onChange={onChange}
                placeholder="eg. 91123455334"
                className="form-control"
                name="phoneNumber"
                id="exampleInputPassword1"
                required
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>

        <table>
        <thead>
          <th style={{ display: checkItemsInPublic("name") ? "" : "none" }}>
            Name
          </th>
          <th style={{ display: checkItemsInPublic("email") ? "" : "none" }}>
            Email
          </th>
          <th
            style={{ display: checkItemsInPublic("phoneNumber") ? "" : "none" }}
          >
            Phone Number
          </th>
          <th style={{ display: checkItemsInPublic("zipcode") ? "" : "none" }}>
            Zip Code
          </th>
          <th style={{ display: checkItemsInPublic("city") ? "" : "none" }}>
            City
          </th>
          <th style={{ display: checkItemsInPublic("county") ? "" : "none" }}>
            County
          </th>
          <th
            style={{
              display: checkItemsInPublic("memberStatus") ? "" : "none",
            }}
          >
            Member Status
          </th>
          <th
            style={{
              float: "right",
              display: pageType === "admin" ? "display" : "none",
            }}
          >
            Action
          </th>
          </thead>
          <tbody>

          {allUsers.map((user) => (
            <tr key={"user_"+user.id}>
              <td style={{ display: checkItemsInPublic("name") ? "" : "none" }}>
                {user.firstName + " " + user.lastName}
              </td>
              <td
                style={{ display: checkItemsInPublic("email") ? "" : "none" }}
              >
                {user.email}
              </td>
              <td
                style={{
                  display: checkItemsInPublic("phoneNumber") ? "" : "none",
                }}
              >
                {+user.countryCode + "-" + user.phoneNumber}
              </td>
              <td
                style={{ display: checkItemsInPublic("zipcode") ? "" : "none" }}
              >
                {user.zipCode}
              </td>
              <td style={{ display: checkItemsInPublic("city") ? "" : "none" }}>
                {user.city}
              </td>
              <td
                style={{ display: checkItemsInPublic("county") ? "" : "none" }}
              >
                {user.zipInfo?.county}
              </td>
              <td
                style={{
                  display: checkItemsInPublic("memberStatus") ? "" : "none",
                }}
              >
                {user.mapStatusInfo?.statusName}
              </td>
              <td  style={{
                    float: "right",
                    display: pageType === "admin" ? "display" : "none",
                  }}>
                <button
                  style={{
                    float: "right",
                    display: pageType === "admin" ? "display" : "none",
                  }}
                  onClick={() => {
                    makeLead(
                      user.id,
                      user.firstName + " " + user.lastName,
                      user.countryCode + user.phoneNumber
                    );
                  }}
                  className="btn btn-primary"
                >
                  Make Lead
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
