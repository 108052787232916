import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { AppContext } from "../../AppContext";
import CommunityForm from "../../users/CommunityForm";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast } from "react-toastify";
import { callAdminApi } from "../../API/ApiInterface";
import { customStyles } from "../../CustomStyle";
import LoadingSpinner from "../../Spinner";

export default function CommunityListItem({ onSelectModal, contacts }) {
  const { checkItems, setCheckItems, setUserDeleted } = useContext(AppContext);
  const [viewItem, setViewItem] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);

  function closeModal() {
    setIsOpen(false);
    onSelectModal(true);
  }
  const maangeSelectedItems = (checked) => {
    if (!checked) setCheckItems([...[]]);
    else {
      setCheckItems(contacts.map((item) => item.id));
    }
  };

  useEffect(() => {
    const userId = searchParams.get("userId");

    if (userId) {
      setViewData(Number(userId));
    }
  }, [contacts]);

  const setViewData = (id) => {
    const data = contacts.find((item) => item.id === id);
    setTimeout(() => {
      setIsOpen(true);
      onSelectModal(false);
    }, 1000);
    setViewItem(data);
  };

  const viewPDF = (id) => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/community/generatePDF",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({
          id: id,
        }),
        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        setLoading(false);

        if (data.code === 200) {
          const path =
            process.env.REACT_APP_BACKEND_URL +
            "docs/community_forms/community_form_" +
            id +
            ".pdf";

          window.open(path, "_blank");
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setLoading(false);

        console.log(e);
        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const checkUncheck = (checked, id) => {
    if (checked) checkItems.push(id);
    else {
      var index = checkItems.indexOf(id);

      if (index !== -1) {
        checkItems.splice(index, 1);
      }
    }
    setCheckItems([...checkItems]);
  };

  const deleteItem = (event, id) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => acceptFunc(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });

    const acceptFunc = (id) => {
      callAdminApi(
        process.env.REACT_APP_BASEURL + "/admin/community/delete",
        "DELETE",
        {
          type: "json",
          auth: true,
          body: JSON.stringify({
            id: id,
          }),
          // body:JSON.stringify({leads:selctedIds})
        }
      )
        .then(async (data) => {
          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setUserDeleted(true);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          toast.success(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <div
        id="customers"
        className="infowindow overflow-auto"
        style={{ height: "500px" }}
      >
        <table>
          <thead>
            <tr>
              <th>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={checkItems.length > 0 ? "checked" : ""}
                  onChange={(e) => maangeSelectedItems(e.target.checked)}
                  name="selectAll"
                />
                Select {contacts.length} entries
              </th>

              <th>Name</th>
              <th>Email</th>
              <th>Contact</th>
              <th>Address</th>
              <th>Zip Code</th>
              <th>Submitted Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((user) => (
              <tr>
                <td>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => checkUncheck(e.target.checked, user.id)}
                    checked={checkItems.includes(user.id) ? "checked" : ""}
                    name="selectedIds"
                  ></input>
                </td>
                <td>{user.firstName + " " + user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.phone_number}</td>
                <td>{user.address}</td>
                <td>{user.zipcode}</td>
                <td>{new Date(user.createdAt).toLocaleString()}</td>

                <td>
                  <button
                    onClick={() => setViewData(user.id)}
                    className="btn btn-warning  mx-1 btn-xs"
                  >
                    View
                  </button>

                  <button
                    className="btn btn-info  mx-1 btn-xs"
                    onClick={() => viewPDF(user.id)}
                  >
                    View PDF
                  </button>

                  <button
                    onClick={(e) => deleteItem(e, user.id)}
                    className="btn btn-xs btn-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        appElement={document.getElementById("root")}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <button onClick={closeModal}>close</button>

        <CommunityForm action="update" viewitem={viewItem}></CommunityForm>
      </Modal>
    </>
  );
}
