import React, { useEffect, useState, useRef, useContext } from "react";
import Modal from "react-modal";

import { MDBCheckbox } from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { GoogleApiWrapper } from "google-maps-react-18-support";
import Accordion from "react-bootstrap/Accordion";
import Search from "./Search";
import { searchZipCode } from "../API/ApiInterface";
import { AppContext } from "../AppContext";
import { customStyles } from "../CustomStyle";
export function Home({ google }) {
  const mapRef = useRef(null);
  const [modalIsOpen, setIsOpen] = useState(false);

  const [viewZipcode, setCurrentViewCode] = useState(0);
  const searchRef = useRef(null);
  const [storedMap, setMap] = useState(null);
  let map;
  let featureLayerZipCode;
  let infoWindow;
  const [selctedIds, setCheckValue] = useState([]);
  const [totalUsersLead, updateTotalUsers] = useState(0);
  const [search, setSearch] = useState("");

  const [selectedNesteds, setNestedCheckValue] = useState([]);
  const [allZipCodes, setAllZipCodes] = useState([]);
  const [gmarkers, setGMarkers] = useState([]);
  const [getTimeData, setFirstTimeData] = useState(1);
  const { publicInfo } = useContext(AppContext);
  const [filterInfo, setFilterinfo] = useState(null);

  const effectRan = useRef(false);

  function closeModal() {
    setIsOpen(false);
  }

  const viewZipCodeusers = (zipCode) => {
    setCurrentViewCode(zipCode);
  };

  useEffect(() => {
    if (viewZipcode !== 0) setIsOpen(true);
  }, [viewZipcode]);

  useEffect(() => {
    if (effectRan.current || process.env.NODE_ENV !== "development") {
      const getFilterInfo = () => {
        fetch(process.env.REACT_APP_BASEURL + "/users/getFilterInfo?admin=0", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
          },
        })
          .then(async (result) => {
            const data = await result.json();

            if (data.code === 200) {
              const filters = data.body;

              setFilterinfo(filters);
              setCheckValue(filters?.leadInfo.map((item) => item.id));
              const array = [];

              let sum = 0;
              filters?.leadInfo.map((item) => {
                sum = sum + item.totalSum;
                return item?.zipcodes?.map((innerItem) => {
                  return array.push({ id: innerItem.id, parent: item.id });
                });
              });

              setNestedCheckValue([...array]);
              updateTotalUsers(sum);
            }
          })
          .catch((e) => console.log(e));
      };

      getFilterInfo();
    }

    return () => (effectRan.current = true);
  }, []);

  useEffect(() => {
    if (
      (selctedIds.length > 0 && selectedNesteds.length > 0) ||
      getTimeData > 1
    ) {
      getFilterData();
    }
  }, [selctedIds, selectedNesteds]);

  const checkItemsInPublic = (code) => {
    const findItem = publicInfo.find(
      (item) => item.code === code && item.public === 1
    );
    if (findItem) return true;
    else return false;
  };

  useEffect(() => {
    infoWindow = null;
    const drawRegionsMap = () => {
      if (mapRef.current) {
        if (!storedMap) {
          map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 31.0, lng: -100.0 },
            // center: { lat: 40.76, lng: -101.64 },
            zoom: 7,
            // In the cloud console, configure this Map ID with a style that enables the
            // "Administrative Area Level 1" feature layer.
            mapId: "cea5f368e232b458",
          });
        } else {
          map = storedMap;
        }
        const featureStyleOptions = {
          strokeColor: "#810FCB",
          strokeOpacity: 1.0,
          strokeWeight: 3.0,
          // fillColor: "#ddd2d1",
          fillOpacity: 0.5,
        };

        const featureLayerBoundary = map.getFeatureLayer(
          window.google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1
        );

        if (featureLayerBoundary) {
          featureLayerBoundary.style = (featureStyleFunctionOptions) => {
            // /console.log(featureStyleFunctionOptions.feature)
            const placeId = featureStyleFunctionOptions.feature.placeId;
            if (placeId === "ChIJSTKCCzZwQIYRPN4IGI8c6xY")
              return featureStyleOptions;
          };

          featureLayerZipCode = map.getFeatureLayer(
            window.google.maps.FeatureType.POSTAL_CODE
          );

          if (featureLayerZipCode)
            featureLayerZipCode.style = (featureStyleFunctionOptions) => {
              // /console.log(featureStyleFunctionOptions.feature)
              const placeId = featureStyleFunctionOptions.feature.placeId;
              let obj = allZipCodes.find((o) => o.placeId === placeId);
              // let obj1 = states.find((o) => o.placeId === placeId);

              //console.log("find object..........",obj1)
              //   var max = 0xffffff;

              // let color = "#" + Math.round(Math.random() * max).toString(16);
              // return {
              //   fillColor: color,
              //   fillOpacity: 0.8,
              //   strokeColor: "#999595",
              //   strokeOpacity: 10.5,
              //   strokeWeight: 1.0,
              // };

              if (obj) {
                //if(placeId=="ChIJSTKCCzZwQIYRPN4IGI8c6xY") return featureStyleOptions;

                //   // if (obj.color) color = obj.color;
                //   // else obj.color = color;

                //   // allZipCodes[foundIndex] = obj;

                return {
                  fillColor: obj.lead ? obj.lead?.color : "",
                  fillOpacity: 0.8,
                  strokeColor: "#999595",
                  strokeOpacity: 10.5,
                  strokeWeight: 1.0,
                };
              }
            };
          setMap(map);
          infoWindow = new window.google.maps.InfoWindow();

          google.maps.event.clearListeners(featureLayerZipCode, "click");

          // featureLayerZipCode.addListener("click",handleClick)
          featureLayerZipCode.addListener("click", function (e) {
            createInfoWindow(e);
          });

          // Population data by state.
        }
      }
    };

    drawRegionsMap();
  }, [allZipCodes]);

  useEffect(() => {
    if (search !== "") {
      searchZipCode(search)
        .then(async (result) => {
          const data = await result.json();
          if (data.code === 200) {
            const latitude = data.body.latitude;
            const longitude = data.body.longitude;
            // storedMap.setCenter(new window.google.maps.LatLng(Number(latitude),Number(longitude)));
            const location = new window.google.maps.LatLng(
              Number(latitude),
              Number(longitude)
            );
            const myLatLng = { lat: Number(latitude), lng: Number(longitude) };

            storedMap.panTo(location);
            storedMap.setZoom(9);
            const marker = new window.google.maps.Marker({
              position: myLatLng,
              title: "Hello World!",
            });

            for (let i = 0; i < gmarkers.length; i++) {
              gmarkers[i].setMap(null);
            }

            marker.setMap(storedMap);
            gmarkers.push(marker);
            setGMarkers([...gmarkers]);
          } else {
            alert("No result");
          }
        })
        .catch((e) => console.log(e));
    }
  }, [search]);

  const getStatusData = async (zipCode) => {
    return fetch(
      process.env.REACT_APP_BASEURL +
        "/users/getMapStatusData?zipCode=" +
        zipCode,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },

        // body:JSON.stringify({leads:selctedIds})
      }
    );
  };
  // const getCheckedDetail = (levelName, id, parentId) => {
  //   if (levelName == "child") {
  //     if (selctedIds.includes(parentId)) return true;
  //     else return false;
  //   }
  // };

  const updateInfoWindow = (content, center) => {
    infoWindow.setContent(content);
    infoWindow.setPosition(center);

    infoWindow.open({
      map,
      shouldFocus: false,
    });
  };
  const createInfoWindow = async (event) => {
    let feature = event.features[0];

    if (!feature.placeId) return;

    // Update the infowindow.
    const place = await feature.fetchPlace();

    //updatePlaceId(feature.placeId, place.displayName);
    await getStatusData(place.displayName)
      .then(async (result) => {
        const data = await result.json();

        if (data.code === 200) {
          let obj = allZipCodes.find((o) => o.placeId === feature.placeId);

          let content = `<div id="customers" class="fd-6 infowindow text-start"><span class="fs-6">
        <table>
       
        <tr style="display:${
          checkItemsInPublic("zipcode") ? "" : "none"
        }">
        <td >Zip Code</td>
        <td>${checkItemsInPublic("zipcode") ? place.displayName : ""}</td>
        </tr>

        <tr style="display:${
          checkItemsInPublic("leadname") ? "" : "none"
        }">        <td>Lead Name</td>
        <td>${
          checkItemsInPublic("leadname")
            ? obj?.lead
              ? obj.lead?.name
              : "Not Assigned"
            : ""
        }</td>
        </tr>

        <tr style="display:${
          checkItemsInPublic("city") ? "" : "none"
        }">           <td>City Name</td>
        <td>${checkItemsInPublic("city") ? obj?.primaryCity : ""}</td>
        </tr>

        <tr>
        <td>Total Count</td>
        <td>${obj?.totalCount ? obj.totalCount : 0}</td>
        </tr>

        <tr style="display:${
          checkItemsInPublic("memberStatus") ? "" : "none"
        }">           <td>Member Status</td>
        <td>
        <div>${
          checkItemsInPublic("memberStatus")
            ? data.body
                ?.map(
                  (item) =>
                    `<p class="m-0">${item.statusName} : ${item.users?.length}</p>`
                )
                .join("<br>")
            : ""
        }</div>

    </td>
        </tr>    

        </table>
        </div>`;

          updateInfoWindow(content, event.latLng);
        }

        //
      })

      .catch((err) => console.log(err));
  };

  const getFilterData = () => {
    fetch(process.env.REACT_APP_BASEURL + "/users/getFilterData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authRequired: "no",
        "access-control-allow-origin": "*",
      },
      body: JSON.stringify({
        leads: selctedIds,
        zipCodes: selectedNesteds.map((ids) => ids.id),
        userRequired:true
      }),
      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (result) => {
        setFirstTimeData(2);
        const data = await result.json();

        if (data.code === 200) {
          const result = data.body;

          const allzips = [];

          result?.map((item) => {
            return allzips.push(item);
          });

          setAllZipCodes(allzips.flat());
        }
      })
      .catch((e) => console.log(e));
  };

  const setCheckedValue = (checked, levelName, id, parentId = 0) => {
    if (levelName === "child" && selctedIds.includes(parentId)) {
      if (checked) {
        selectedNesteds.push({ id: id, parent: parentId });
        setNestedCheckValue([...selectedNesteds]);
      } else {
        // var index = selectedNesteds.indexOf(Number(id));
        // if (index !== -1) {
        //   selectedNesteds.splice(index, 1);
        // }

        const newArray = selectedNesteds.filter(function (obj) {
          return obj.id !== id;
        });
        setNestedCheckValue([...newArray]);
      }
    } else {
      if (levelName === "lead" && checked) {
        selctedIds.push(Number(id));
      }

      if (levelName === "lead" && !checked) {
        //selctedIds.pu(id)
        var index = selctedIds.indexOf(Number(id));

        // alert(index)
        if (index !== -1) {
          selctedIds.splice(index, 1);
        }
      }

      const newArr = [...selctedIds];

      setCheckValue(newArr);
    }
  };

  return (
    <div className="bodyNav row">
      <div className="col-md-7 border row mx-2  my-3">
        <div
          style={{
            borderStyle: "groove",
            height: "700px",
            width: "1000px",
          }}
          ref={mapRef}
          className="google-map"
        >
          <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            appElement={document.getElementById("root")}
            contentLabel="Example Modal"
          >
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <button onClick={closeModal}>close</button>

            <Search
              pageType="viewAll"
              requestType="user"
              zipCode={viewZipcode}
            ></Search>
          </Modal>
        </div>
      </div>
      <div className="col-md-4 border mx-2 row my-3">
        <div>
          <input
            type="text"
            ref={searchRef}
            placeholder="Search by Zip Code"
            className="mt-2 form-control mt-2 p-2"
          />
          <button
            onClick={(e) => setSearch(searchRef.current.value)}
            className="btn  col-lg-12 btn-success btn-sm"
          >
            Search
          </button>
          <Accordion
            
            className="my-4"
            defaultActiveKey="0"
          >
            <Accordion.Item  style={{
              display: checkItemsInPublic("leadsControl") ? "block" : "none",
            }} eventKey="0">
              <Accordion.Header>
                <span className="fs-5">
                  Leads Control &nbsp;(Total Leads:{" "}
                  {filterInfo?.leadInfo.length} , Total users: {totalUsersLead})
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {checkItemsInPublic("leadsControl") && filterInfo &&
                  filterInfo?.leadInfo.map((item) => (
                    <div key={item.id}>
                      <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey={item.id}>
                          {/* {alert(selctedIds.includes(item.id))} */}
                          <Accordion.Header>
                            <MDBCheckbox
                              id={item.id}
                              label={item.name + " - " + item.totalSum}
                              checked={
                                selctedIds.includes(item.id) ? "checked" : ""
                              }
                              onChange={(e) =>
                                setCheckedValue(
                                  e.target.checked,
                                  "lead",
                                  `${item.id}`
                                )
                              }
                            />
                          </Accordion.Header>
                          <Accordion.Body style={{ paddingTop: "1px" }}>
                            {item?.zipcodes?.map((innerItem, index) => (
                              <div
                                key={"chiled_" + index + "_" + innerItem}
                                className="listItem"
                              >
                                <MDBCheckbox
                                  id={innerItem.id}
                                  checked={
                                    selctedIds.includes(item.id) &&
                                    selectedNesteds.find(
                                      (item) => item.id === innerItem.id
                                    )
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setCheckedValue(
                                      e.target.checked,
                                      "child",
                                      innerItem.id,
                                      item.id
                                    )
                                  }
                                  inline
                                />
                                <p>{innerItem.zipCode}</p>
                                <p className="mx-3">
                                  Total Users: {innerItem.totalCount}{" "}
                                  <button
                                    style={{
                                      display:
                                        innerItem.totalCount > 0
                                          ? "inline"
                                          : "none",
                                    }}
                                    className="btn btn-warning btn-sm"
                                    onClick={() => {
                                      viewZipCodeusers(innerItem.zipCode);
                                    }}
                                  >
                                    View All
                                  </button>
                                </p>
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              style={{
                display: checkItemsInPublic("geoControl") ? "block" : "none",
              }}
              eventKey="1"
            >
              <Accordion.Header>
                <span className="fs-5">
                  Geo Control (Total County: {filterInfo?.countyInfo.length})
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {checkItemsInPublic("geoControl") &&  filterInfo &&
                  filterInfo?.countyInfo.map((item, index) => (
                    <div key={"country_" + item.id + "_" + index}>
                      <Accordion
                        id={"county_" + item.id}
                        defaultActiveKey="county_1"
                      >
                        <Accordion.Item eventKey={"county_" + item.id}>
                          <Accordion.Header>
                            <p> {item.county ? item.county : "NA"}</p>
                            <button
                              style={{
                                display:
                                  item.totalCount > 0 ? "inline" : "none",
                              }}
                              className="btn mx-4  mb-3 btn-info btn-xs"
                              onClick={() => {
                                viewZipCodeusers(item?.allZipCodes?.split(","));
                              }}
                            >
                              {item.totalCount}-Users
                            </button>
                          </Accordion.Header>
                          {/* <Accordion.Body>
                          {item?.allZipCodes
                            ?.split(",")
                            .map((innerItem, index) => (
                              <div
                                key={"innerItem" + innerItem + "_" + index}
                                className="listItem"
                              >
                                <p>{innerItem}</p>
                              </div>
                            ))}
                        </Accordion.Body> */}
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAPKEY,
})(Home);
