import React, { useEffect, useState, useRef, useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { callAdminApi } from "../../API/ApiInterface";
import LoadingSpinner from "../../Spinner";
import { AppContext } from "../../AppContext";

export default function EditTemplate({ templateDetail }) {
  const [isLoading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({ name: templateDetail?.name });
  const { setUserUpdated } = useContext(AppContext);

  const editorRef = useRef(null);

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const editTemplate = () => {
    setLoading(true);
    let content = "";
    if (templateDetail.templateType === 2) {
      content = document.getElementById("smsContent").value;
    } else {
      content = editorRef.current.getContent();
    }

    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/templates/edit",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({
          content: content,
          name: inputData.name,
          id: templateDetail?.id,
        }),
      }
    )
      .then(async (data) => {
        setLoading(false);
        setUserUpdated(true);
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  function setTextToCurrentPos(element, text_to_insert) {
    var curPos = element.selectionStart;
    let x = element.value;
    element.value = x.slice(0, curPos) + text_to_insert + x.slice(curPos);
  }

  const appendReference = (value) => {
    if (templateDetail?.templateType === 2) {
      const element = document.getElementById("smsContent");
      setTextToCurrentPos(element, `[[${value}]]`);
    } else {
      if (editorRef.current) {
        editorRef.current.execCommand(
          "mceInsertContent",
          false,
          `[[${value}]]`
        );
      }
    }
  };

  const loadTemplate = (id) => {
    try {
      let content = templateDetail?.content;
      if (templateDetail?.templateType === 2) {
        document.getElementById("smsContent").value = content.replace(
          /<[^>]*>/g,
          ""
        );
      } else {
        setTimeout(function () {
          editorRef.current?.setContent(content);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadTemplate();
  }, []);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="container row">
        <label htmlFor="exampleInputEmail1" className="form-label  mt-4">
          <b>Template Name</b>
        </label>
        <input
          type="text"
          className="form-control text-center mx-2 "
          id="name"
          name="name"
          maxLength={50}
          value={inputData.name}
          onChange={onChange}
          required
        />

        <label htmlFor="exampleInputEmail1" className="form-label  mt-4">
          <b>Template Content</b>
        </label>
        <div
          style={{
            display: templateDetail?.templateType === 1 ? "inline" : "none",
          }}
          className="col-md-9 mt-2"
        >
          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue="<p>Write your customized email content here</p>"
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={{
              height: "500px",
              plugins:
                "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              mergetags_list: [
                { value: "First.Name", title: "First Name" },
                { value: "Email", title: "Email" },
              ],
              ai_request: (request, respondWith) =>
                respondWith.string(() =>
                  Promise.reject("See docs to implement AI Assistant")
                ),
            }}
          />

          <button
            className="ms-1 my-3 btn btn-secondary"
            onClick={() => editTemplate()}
            size="lg"
          >
            Save Template
          </button>
        </div>
        <div
          style={{
            display: templateDetail?.templateType === 2 ? "inline" : "none",
          }}
          className="col-md-9 mt-2"
        >
          <textarea
            id="smsContent"
            className="form-control "
            placeholder="Write your SMS content here..."
            maxLength={300}
            rows="15"
          ></textarea>

          <button
            className="ms-1 my-3 btn btn-success"
            onClick={() => editTemplate()}
            size="lg"
          >
            Save Template
          </button>
        </div>
        <div className="col-md-3">
          <button
            onClick={() => appendReference("name")}
            className="btn btn-sm btn-info mt-1"
          >
            Name
          </button>
          <button
            onClick={() => appendReference("email")}
            className="btn btn-sm btn-info mx-1 mt-1"
          >
            Email
          </button>
          <button
            onClick={() => appendReference("contact")}
            className="btn btn-sm btn-info  mx-1 mt-1"
          >
            Contact
          </button>
          <button
            onClick={() => appendReference("address")}
            className="btn btn-sm btn-info  mx-1 mt-1"
          >
            Address
          </button>
          <button
            onClick={() => appendReference("city")}
            className="btn btn-sm btn-info  mx-1 mt-1"
          >
            City
          </button>
          <button
            onClick={() => appendReference("zipCode")}
            className="btn btn-sm btn-info  mx-1 mt-1"
          >
            Zip Code
          </button>

          <button
            onClick={() => appendReference("memberStatus")}
            className="btn btn-sm btn-info  mx-1 mt-1"
          >
            Member Status
          </button>

          <button
            onClick={() => appendReference("userId")}
            className="btn btn-sm btn-info mt-1"
          >
            User Id
          </button>

          <button
            onClick={() => appendReference("UNSUBSCRIBE")}
            className="btn btn-sm btn-info mt-1"
          >
            Unsubscribe
          </button>
        </div>
      </div>
    </>
  );
}
