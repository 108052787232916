import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react"
import { MDBCheckbox } from "mdb-react-ui-kit";
import Modal from "react-modal";
import { callAdminApi, searchZipCode } from "../API/ApiInterface";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { toast } from "react-toastify";
import { customStyles } from "../CustomStyle";
// import states from "./data.json";
import { GoogleApiWrapper } from "google-maps-react-18-support";
import Accordion from "react-bootstrap/Accordion";
import Search from "../users/Search";
import { AppContext } from "../AppContext";
export function AdminHomeTest({ google }) {
  const mapRef = useRef(null);
  const [storedMap, setMap] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [viewZipcode, setCurrentViewCode] = useState(0);
  const { leadsUpdate, setLeadsUpdate } = useContext(AppContext);
  const [infoWRecords, setAllInfoWindo] = useState([]);
  const [infoWindow, setInfowindow] = useState(null);
  const searchRef = useRef(null);
  const [gmarkers, setGMarkers] = useState([]);
  const [search, setSearch] = useState("");

  let map;

  const [selctedIds, setCheckValue] = useState([]);

  const [selectedNesteds, setNestedCheckValue] = useState([]);
  const [allZipCodes, setAllZipCodes] = useState([]);

  const [filterInfo, setFilterinfo] = useState(null);

  const effectRan = useRef(false);



  const getFilterData = useCallback(() => {
    callAdminApi(process.env.REACT_APP_BASEURL + "/users/getFilterData", "POST", {
      type:'json',
      auth:true,
      body: JSON.stringify({
        leads: selctedIds,
        zipCodes: selectedNesteds.map((ids) => ids.id),
      }),
      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (data) => {

        if (data.code === 200) {
          const result = data.body;

          const allzips = [];

          result?.map((item) => {
            return allzips.push(item);
          });

          setAllZipCodes([...allzips.flat()]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selctedIds, selectedNesteds]);

  const getFilterInfo = useCallback(() => {
    fetch(process.env.REACT_APP_BASEURL+"/users/getFilterInfo?admin=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
    }).then(async (result) => {
      const data = await result.json();
      setLeadsUpdate(false)

      if (data.code === 200) {
        const filters = data.body;

        setFilterinfo(filters);
        setCheckValue(filters?.leadInfo.map((item) => item.id));
        const array = [];

        filters?.leadInfo.map((item) => {
          return item?.zipcodes?.map((innerItem) => {
            return array.push({ id: innerItem.id, parent: item.id });
          });
        });

        setNestedCheckValue(array);
      }
    });
  }, [leadsUpdate]);

  useEffect(() => {
    if (effectRan.current || process.env.NODE_ENV !== "development") {
      getFilterInfo();
    }

    return () => (effectRan.current = true);
  }, []);




  useEffect(() => {
    if (leadsUpdate) {
      infoWRecords.map((iW) => {
        if (iW != null) 
        return iW.close();
      return iW;
      });
      setIsOpen(false);
      setTimeout(() => {
        getFilterData();
        getFilterInfo();

      }, 500);
    }
  }, [leadsUpdate]);

  useEffect(() => {
    if (search !== "") {
      searchZipCode(search)
        .then(async (result) => {
          const data = await result.json();
          if (data.code === 200) {
            const latitude = data.body.latitude;
            const longitude = data.body.longitude;
            // storedMap.setCenter(new window.google.maps.LatLng(Number(latitude),Number(longitude)));
            const location = new window.google.maps.LatLng(
              Number(latitude),
              Number(longitude)
            );
            const myLatLng = { lat: Number(latitude), lng: Number(longitude) };

            storedMap.panTo(location);
            storedMap.setZoom(10);
            const marker = new window.google.maps.Marker({
              position: myLatLng,
              title: "Hello World!",
            });

            for (let i = 0; i < gmarkers.length; i++) {
              gmarkers[i].setMap(null);
            }

            marker.setMap(storedMap);
            gmarkers.push(marker);
            setGMarkers([...gmarkers]);
          }
          else{
            toast.error("No result", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => console.log(e));
    }
  }, [search]);
  useEffect(() => {
    setInfowindow(null);
    const drawRegionsMap = () => {
      if (mapRef.current) {
        if (!storedMap) {
          map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 31.0, lng: -100.0 },
            // center: { lat: 40.76, lng: -101.64 },
            zoom: 7,
            // In the cloud console, configure this Map ID with a style that enables the
            // "Administrative Area Level 1" feature layer.
            mapId: "cea5f368e232b458",
          });
        } else {
          map = storedMap;
        }
        const featureStyleOptions = {
          strokeColor: "#810FCB",
          strokeOpacity: 1.0,
          strokeWeight: 3.0,
          // fillColor: "#ddd2d1",
          fillOpacity: 0.5,
        };

        const featureLayerBoundary = map.getFeatureLayer(
          window.google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1
        );

        featureLayerBoundary.style = (featureStyleFunctionOptions) => {
          const placeId = featureStyleFunctionOptions.feature.placeId;
          if (placeId === "ChIJSTKCCzZwQIYRPN4IGI8c6xY")
            return featureStyleOptions;
        };

        const featureLayerZipCode = map.getFeatureLayer(
          window.google.maps.FeatureType.POSTAL_CODE
        );

        featureLayerZipCode.style = (featureStyleFunctionOptions) => {
          const placeId = featureStyleFunctionOptions.feature.placeId;
          // setTimeout(async()=>{
          // //  const place = await featureStyleFunctionOptions.feature.fetchPlace();

          //   updatePlaceId(placeId,place.displayName)
          // },1000)
          let obj = allZipCodes.find((o) => o.placeId === placeId);
          let color = "#052c65";


        

          if (obj) {
            color = obj.lead ? obj.lead?.color : "";
           
          }

          return {
            fillColor: color,
            fillOpacity: 0.8,
            strokeColor: "#6c6767",
            strokeOpacity: 10.5,
            strokeWeight: 1.0,
          };
        };

        setMap(map);
        setInfowindow(new window.google.maps.InfoWindow());
        infoWRecords.push(infoWindow);
        setAllInfoWindo([...infoWRecords]);
        google.maps.event.clearListeners(featureLayerZipCode, "click");

        // featureLayerZipCode.addListener("click",handleClick)
        featureLayerZipCode.addListener("click", function (e) {
          createInfoWindow(e);
        });

        // Population data by state.
      }
    };

    drawRegionsMap();
  }, [allZipCodes]);

  useEffect(() => {
    getFilterData();
  }, [selctedIds, selectedNesteds]);


  function openModal() {
    setIsOpen(true);
    setLeadsUpdate(false);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const updatePlaceId = (placeId, zipCode) => {
    updatePlaceIdApi(zipCode, placeId);
  };

  // const viewMember=(id)=>{
  //   alert(id)
  // }

  // const getCheckedDetail = (levelName, id, parentId) => {
  //   if (levelName == "child") {
  //     if (selctedIds.includes(parentId)) return true;
  //     else return false;
  //   }
  // };

 


  const createInfoWindow = async (event) => {
    let feature = event.features[0];

    if (!feature.placeId) return;

    // Update the infowindow.
    const place = await feature.fetchPlace();

    updatePlaceId(feature.placeId, place.displayName);
    
  };


  const updatePlaceIdApi = (zipCode, placeId) => {
    fetch(process.env.REACT_APP_BASEURL + "/test/updatePlaceId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
      body: JSON.stringify({
        zipCode: zipCode,
        placeId,
      }),
      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (result) => {
        const data = await result.json();
        console.log(zipCode,placeId,data)

      })
      .catch((err) => console.log(err));
  };


  const setCheckedValue = (checked, levelName, id, parentId = 0) => {
    if (levelName === "child" && selctedIds.includes(parentId)) {
      if (checked) {
        selectedNesteds.push({ id: id, parent: parentId });
        setNestedCheckValue([...selectedNesteds]);
      } else {
        // var index = selectedNesteds.indexOf(Number(id));
        // if (index !== -1) {
        //   selectedNesteds.splice(index, 1);
        // }

        const newArray = selectedNesteds.filter(function (obj) {
          return obj.id !== id;
        });
        setNestedCheckValue([...newArray]);
      }
    } else {
      if (levelName === "lead" && checked) {
        selctedIds.push(Number(id));
      }

      if (levelName === "lead" && !checked) {
        //selctedIds.pu(id)
        var index = selctedIds.indexOf(Number(id));

        // alert(index)
        if (index !== -1) {
          selctedIds.splice(index, 1);
        }
      }

      const newArr = [...selctedIds];

      setCheckValue(newArr);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        appElement={document.getElementById("root")}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <button    onClick={closeModal}>close</button>

        <Search pageType="admin" zipCode={viewZipcode}></Search>
        {/* 
        <table>
        <thead>

       
        </thead>
        {statusInfo?.map(
          (outerItem)=>(
  <tr>
    <td>{outerItem.statusName}</td>
    <td>
      <table style={{display:outerItem.users?.length>0?"block":"none"}}>
        <th>Name</th>
        <th>Email</th>
        <th>Phone Number</th>
      {outerItem?.users?.map((innerItem) => (
 <tr>
 <td>{innerItem.firstName+" "+innerItem.lastName}</td>
 <td>{innerItem.email}</td>
 <td>{innerItem.phoneNumber}</td>
 
 
 </tr>
      ))}
      </table>
    </td>
  </tr>
          ))}
</table> */}
      </Modal>

      <div className="bodyNav row">
        <div className="col-md-8 border row mx-2  my-3">
          <div
            style={{
              border: `2px`,
              borderStyle: "groove",
            }}
            ref={mapRef}
            className="google-map"
          >
            {/* <Map
                    google={google}

                    zoom={10}
                    id="myTable"
                    className="mapContainerWrapper"
                    style={mapStyles}
                    initialCenter={{
                      lat:  31.000000,
                      lng: -100.0,
                    }}
                  >
                    <Marker
                      onClick={onMarkerClick}
                      name={"Kenyatta International Convention Centre"}
                    />
                    <InfoWindow
                      marker={mapState.activeMarker}
                      visible={mapState.showingInfoWindow}
                      onClose={onClose}
                    >
                      <div>
                        <h4>{mapState.selectedPlace.name}</h4>
                      </div>
                    </InfoWindow>
                  </Map> */}
          </div>
        </div>
        <div className="col-md-3 border row mx-2  my-3">
        <input type="text"
          ref={searchRef}
          placeholder="Search by Zip Code"
          className=" mt-2 form-control mt-2 p-2"
        />
        <button
          onClick={(e) => setSearch(searchRef.current.value)}
          className="btn btn-success btn-sm"
        >
          Search
        </button>
          <Accordion className="my-5" defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Leads Control</Accordion.Header>
              <Accordion.Body>
                {filterInfo &&
                  filterInfo?.leadInfo.map((item) => (
                    <div key={item.id}>
                      <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey={item.id}>
                          {/* {alert(selctedIds.includes(item.id))} */}
                          <Accordion.Header>
                            <MDBCheckbox
                              id={item.id}
                              label={item.name}
                              checked={
                                selctedIds.includes(item.id) ? "checked" : ""
                              }
                              onChange={(e) =>
                                setCheckedValue(
                                  e.target.checked,
                                  "lead",
                                  `${item.id}`
                                )
                              }
                            />
                          </Accordion.Header>
                          <Accordion.Body style={{ paddingTop: "1px" }}>
                            {item?.zipcodes?.map((innerItem) => (
                              <div
                                key={"leadsChild_" + innerItem.id}
                                className="listItem"
                              >
                                <MDBCheckbox
                                  id={innerItem.id}
                                  checked={
                                    selctedIds.includes(item.id) &&
                                    selectedNesteds.find(
                                      (item) => item.id === innerItem.id
                                    )
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setCheckedValue(
                                      e.target.checked,
                                      "child",
                                      innerItem.id,
                                      item.id
                                    )
                                  }
                                  inline
                                />
                                <p>{innerItem.zipCode}</p>
                                <p className="mx-3">
                                  Total Users: {innerItem.totalCount}{" "}
                                  <button
                                    style={{
                                      display:
                                        innerItem.totalCount > 0
                                          ? "inline"
                                          : "none",
                                    }}
                                    className="btn btn-warning btn-sm"
                                    onClick={() => {
                                      setCurrentViewCode(innerItem.zipCode);
                                      openModal();
                                    }}
                                  >
                                    View All
                                  </button>
                                </p>
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Geo Control</Accordion.Header>
              <Accordion.Body>
                {filterInfo &&
                  filterInfo?.countyInfo.map((item) => (
                    <div>
                      <Accordion
                        id={"county_" + item.id}
                        defaultActiveKey="county_1"
                      >
                        <Accordion.Item eventKey={"county_" + item.id}>
                          <Accordion.Header>
                            <MDBCheckbox
                              id={item.id}
                              label={item.county ? item.county : "NA"}
                              // checked={checked}
                              // onChange={() => setChecked(!checked)}
                            />
                            <button className="mx-4 btn-xs my-1">{item.totalCount} users</button>
                          </Accordion.Header>
                          <Accordion.Body>
                            {item?.allZipCodes?.split(",").map((innerItem) => (
                              <div className="listItem">
                                <MDBCheckbox id={"child_" + innerItem} inline />
                                <p>{innerItem}</p>
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAPKEY,
})(AdminHomeTest);
