import React, { useEffect, useState, useContext, useCallback } from "react";
import { AppContext } from "../../AppContext";
import { callAdminApi } from "../../API/ApiInterface";
import LoadingSpinner from "../../Spinner";
import StatusItem from "./StatusItem";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateMemberList } from "../../../store/slices/memberStatusSlice";

export default function Status() {
  const [allStatus, setAllStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const initialState = {
    name: "",
    planAmount: "0",
    id: "",
  };

  const [formValues, setFormValues] = useState(initialState);

  const { userUpdated, setUserUpdated } = useContext(AppContext);

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();
    makeStatus(formValues.name, formValues.planAmount);
  };
  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const makeStatus = (name = "", planAmount = "0") => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/status/createStatus",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({
          name: name,
          planAmount,
        }),
        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        setLoading(false);
        setUserUpdated(true);
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setFormValues(initialState);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const getStatusInfo = useCallback(() => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL +
        "/users/getMapStatusData?admin=1&search=" +
        search,
      "GET",
      { type: "", body: null, auth: true }
    )
      .then(async (data) => {
        setLoading(false);
        setUserUpdated(false);
        if (data.code === 200) {
          const filters = data.body;

          const array = [];

          filters?.map((item) => {
            return array.push({
              id: item.id,
              statusName: item.statusName,
              planAmount: item.planAmount,
              active: item.active,
              label: item.statusName,
              value: item.id,
            });
          });
          setAllStatus([...array]);
          dispatch(updateMemberList([...array]));
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [search]);

  useEffect(() => {
    getStatusInfo();
  }, [search, userUpdated]);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="bodyNav row">
        <b className="fs-3 mx-5 my-2 text-start">Member Status Control</b>
        <div className="col-md-5 border row mx-2  my-3">
          <div className="col-md-5 mx-4">
            <input
              type="text"
              placeholder="Search by (name)"
              value={search}
              className="form-control mt-2 mx-2 p-2"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="col-md-12 m-3">
            <div>
              <p
                style={{ float: "left", fontSize: "20px" }}
                className="float-left mx-4"
              >
                Total Count: {allStatus.length}
              </p>
            </div>
            <StatusItem allStatus={allStatus} />
          </div>
        </div>

        <div className="col-md-5 justify-content-center my-3 row border">
          <h3 className="mt-3">Add New Member Status</h3>

          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="p-2 col-md-7 "
          >
            <div className="mb-3 mt-3 ">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Status Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                name="name"
                maxLength={30}
                onChange={onChange}
                value={formValues.name}
                aria-describedby="emailHelp"
                required
              />
              <label htmlFor="exampleInputEmail1" className="form-label mt-3 ">
                Plan Amount
              </label>
              <input
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                name="planAmount"
                max={50000}
                min={0}
                value={formValues.planAmount}
                onChange={onChange}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
