import UserItem from "./UserItem";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { AppContext } from "../../AppContext";
import {
  getAllLeads,
  callAdminApi,
  useSecureFetch,
} from "../../API/ApiInterface";
import Swal from "sweetalert2";
import Pagination from "../../Pagination";

import { toast } from "react-toastify";
import LoadingSpinner from "../../Spinner";
import Modal from "react-modal";
import "font-awesome/css/font-awesome.min.css";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import { customStyles } from "../../CustomStyle";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
export default function Users() {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [allLeads, setAllelads] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const [action, setAction] = useState("");
  const [leadId, setSearchLeadId] = useState("");
  const [adminCode, setAdminCode] = useState("");
  const [formData, setFormData] = useState(null);
  const [body, setBody] = useState({ type: "json", data: "", auth: true });
  const [columnModal, setColumnModal] = useState(false);
  const [permissionList, updateCheckedPerm] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [selected, setSelected] = useState([]);
  const allStatus = useSelector((state) => state.memberStatus.data);
  const [nPages, setNPages] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(200);

  const [paymentObj, setPaymentobj] = useState({
    paidAmount: 0,
    planAmount: 0,
    remainingAmount: 0,
  });
  const {
    userUpdated,
    setCheckItems,
    deletedUser,
    setUserDeleted,
    setUserUpdated,
  } = useContext(AppContext);

 

  const { result, error, fetchApi } = useSecureFetch(
    `${process.env.REACT_APP_BASEURL}/users/all`,
    "POST",
    body
  );
  useEffect(() => {
    getAllLaeadsFxn();
    getAllAdmins();
  }, []);

  useEffect(() => {
    const newContent = {
      search: search,
      leadId: leadId,
      mapStatus: selected.map((item) => item.id),
      adminCode: adminCode,
      permissionList,
      checkPermissions: 1,
      page: currentPage,
      limit: recordsPerPage,
    };

    body.data = newContent;

    setBody(body);
    fetchApi();

    setIsLoading(true);

    // fetchApi()
  }, [
    search,
    leadId,
    deletedUser,
    selected,
    userUpdated,
    adminCode,
    currentPage,
  ]);

  useEffect(() => {
    if (action !== "NA") {
      body.data.permissionList = permissionList;
      setBody({ ...body });

      fetchApi();
    }
  }, [action]);

  useEffect(() => {
    setIsLoading(false);
    if (result) {
      if (result.code === 200) {
        if (action === "viewExcel") {
          setIsLoading(true);

          setTimeout(function () {
            const link = document.createElement("a");
            link.href = `${process.env.REACT_APP_BACKEND_URL}/docs/Download-Users-Data.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setAction("NA");
            setIsLoading(false);
          }, 5000);
        } else {
          const resp = result.body;
          if (resp) {
            setContacts([...resp.rows]);
            const numberPages = Math.ceil(resp.count / recordsPerPage);
            setNPages(numberPages);
            setCount(resp.count);
            setTotalSum(resp.sum);
            setUserDeleted(false);
            setUserUpdated(false);
            setPaymentobj(resp.paymentObj);
            setCheckItems(resp.rows.map((item) => item.id));
          }
        }
      }
    }

    if (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [result, error]);

  const getAllLaeadsFxn = useCallback(() => {
    getAllLeads()
      .then(async (result) => {
        const data = await result.json();

        if (data.code === 200) {
          const filters = data.body;

          const array = [];

          filters?.leadInfo.map((item) => {
            return array.push({ id: item.id, name: item.name });
          });
          setAllelads([...array]);
          setUserDeleted(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getAllAdmins = useCallback(() => {
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/list", "POST", {
      type: "json",
      auth: true,
    })
      .then(async (data) => {
        if (data.code === 200) {
          const admins = data.body.rows;

          const array = [];

          admins.map((item) => {
            return array.push(item);
          });
          setAllAdmins([...array]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const updateEditPermission = (checked, moduleId) => {
    let indexF = permissionList.findIndex((ip) => ip.id === moduleId);

    if (indexF < 0) {
      permissionList.push({ id: moduleId, public: checked ? 1 : 0 });
    } else {
      permissionList[indexF] = { id: moduleId, public: checked ? 1 : 0 };
    }

    updateCheckedPerm([...permissionList]);
  };
  const selectAll = (checked) => {
    const newData = [];
    for (let item of allColumns) {
      newData.push({ id: item.id, public: checked ? 1 : 0 });
    }

    updateCheckedPerm([...newData]);
  };

  const excelDownloadUrl = () => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_BACKEND_URL}/docs/exportSample.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      toast.success("Please check downloads directory", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }, 1500);
  };
  const checkedData = (moduleId) => {
    let checked = "";
    const findIndex = permissionList.findIndex(
      (it) => it.id === moduleId && it.public === 1
    );

    if (findIndex >= 0) checked = "checked";
    return checked;
  };
  const downloadData = () => {
    setColumnModal(false);
    setAction("viewExcel");
  };
  const fetchAllColumns = () => {
    setColumnModal(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/useroptions/all",
      "GET",
      {
        type: "json",
        auth: true,
      }
    )
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          setAllColumns([...data.body]);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleFileUpload = (event) => {
    // get the selected file from the input
    const file = event.target.files[0];
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("importFile", file);
    setFormData(formData);
  };

  const importData = () => {
    setIsLoading(true);

    callAdminApi(process.env.REACT_APP_BASEURL + "/users/import", "POST", {
      type: "",
      body: formData,
      auth: true,
    })
      .then(async (data) => {
        setIsLoading(false);

        if (data.code === 200) {
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Download Status",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "File downloaded, Please check downloads directory",
                  showConfirmButton: true,
                  confirmButtonText: `
                  <i class="fa fa-thumbs-up"></i> Great!
                `,
                });
                const link = document.createElement("a");
                link.href = `${process.env.REACT_APP_BACKEND_URL}/docs/Download-Status-Data.xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }, 4000);
            }
          });

          setFormData(null);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);

        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="bodyNav row">
        <div className="col-md-11 border row mx-2  my-3">
          <div className="col-md-3">
            <select
              onChange={(e) => setSearchLeadId(e.target.value)}
              className="form-select mt-2 p-2"
              aria-label=".form-select-lg example"
            >
              <option value="">Select Lead</option>
              {allLeads.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3 mt-2">
            <MultiSelect
              options={allStatus}
              onChange={setSelected}
              labelledBy="Member"
              overrideStrings={{
                selectSomeItems: "Select Member Status",
                allItemsAreSelected: "All status are selected.",
              }}
              value={selected}
            />
          </div>

          <div className="col-md-3">
            <select
              onChange={(e) => setAdminCode(e.target.value)}
              className="form-select mt-2 p-2"
              aria-label=".form-select-lg example"
            >
              <option value="">Select Sub Admin</option>
              {allAdmins.map((item) => (
                <option key={"adminCode_" + item.id} value={item.code}>
                  {item.firstName + "" + item.lastName}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <input
              type="text"
              placeholder="Search by (name,contact,email,zipcode)"
              value={search}
              className="form-control mt-2 p-2"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="col-md-12 m-3">
            <div className="row mt-2 mb-4 ">
              <div className="col-5">
                <input
                  onChange={handleFileUpload}
                  accept=".xlsx, .xls, .csv"
                  className="mb-3  form-control"
                  type="file"
                />
              </div>
              <div className="col-3 row">
                <button
                  disabled={isLoading}
                  onClick={importData}
                  className="btn btn-success m-0 btn-xs"
                >
                  Import Data
                </button>
                <button
                  onClick={excelDownloadUrl}
                  className="btn btn-success mt-1 btn-xs"
                >
                  Export Sample Data
                </button>
              </div>

              <div className="col-4 mx-2 mt-1 ">
                <button
                  onClick={(e) => fetchAllColumns()}
                  className="btn  col-md-5 btn-warning float-end"
                >
                  Download Data
                </button>
              </div>
            </div>

            <div
              style={{ backgroundColor: "#bccfd6" }}
              className="border mt-1 float-right col-md-12  p-1 d-flex  border-solid row"
            >
              <div className="col-md-2">
                <p className="float-right fs-4">
                  <b>Total Count: {count}</b>
                </p>
              </div>

              <div className="col-md-2">
                <p className="fs-4 float-right">
                  <b>Total Sum: {totalSum}</b>
                </p>
              </div>
              <div className="col-md-3">
                <p className="fs-4 mx-5 px-2 float-right ">
                  <b>Total Plan Amount: {paymentObj?.planAmount}</b>
                </p>
              </div>

              <div className="col-md-2">
                <p className="fs-4 float-right">
                  <b>Total Paid Amount: {paymentObj?.paidAmount}</b>
                </p>
              </div>

              <div className="col-md-3">
                <p className="fs-4  float-right">
                  <b> Total Remaining Amount: {paymentObj?.remainingAmount}</b>
                </p>
              </div>
            </div>

            <UserItem allStatus={allStatus} contacts={contacts} />
          </div>
          <div className="my-2 mb-5">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>

        <Modal
          isOpen={columnModal}
          // onAfterOpen={afterOpenModal}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <button onClick={() => setColumnModal(false)}>close</button>
          <div className="col-md-12 mt-3 p-4 ">
            <label className="fs-4 p-2 ">Select Columns to download</label>
            <div className="col-md-12 mt-3 mx-5 text-start">
              <input
                onChange={(e) => selectAll(e.target.checked)}
                className="form-check-input  mt-1"
                type="checkbox"
              />
              <span className="fs-5">Select All</span>
            </div>

            {allColumns?.map((item, index) => (
              <div className="col-md-12 mt-3 mx-5 text-start">
                <input
                  checked={checkedData(item.id)}
                  onChange={(e) =>
                    updateEditPermission(e.target.checked, item.id)
                  }
                  id={item.moduleId}
                  className="form-check-input  mt-1"
                  type="checkbox"
                />
                <span className="fs-5">{item?.option}</span>
                <br></br>
              </div>
            ))}

            <div className="col-md-12 mt-5 mb-2">
              <button onClick={downloadData} className="btn btn-success mx-3 ">
                Download
              </button>

              <button
                onClick={() => setColumnModal(false)}
                className="btn btn-secondary mx-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
