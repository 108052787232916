import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  MDBBtn,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBRadio,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { toast } from "react-toastify";

function Forms() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    countryCode: "1",
    zipCode: "",
    householdSize: "",
    status: "",
    address: "",
    gotra: "",
    paymentType: "",
    paymentPlan: "",
    paidAmount: "",
    planAmount: "",
    remainingAmount: "",
    leadName: "",
    mVCode: "",
    isSpouseAdded: false,
    spFirstName: "",
    spLastName: "",
    comment: "",
  };

  const [timer, setTimer] = useState(90);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const [allStatus, setStatus] = useState([]);
  const [inputData, setInputData] = useState(initialState);
  const [paymentDiv, setPaymentDiv] = useState(false);
  const [validClass, setValidClass] = useState("col-md-8 needs-validation");
  const formRef = useRef(null);

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const onMemberSelection = (statusValue) => {
    inputData.status = statusValue;
    const findItem = allStatus.find((item) => item.id === Number(statusValue));
    if (findItem && findItem.planAmount > 0) {
      setPaymentDiv(true);
      inputData.planAmount = findItem.planAmount;
    } else {
      setPaymentDiv(false);
      inputData.planAmount = 0;
    }

    const remaAmount = inputData.planAmount - inputData.paidAmount;
    inputData.remainingAmount = remaAmount;

    setInputData({ ...inputData });
  };

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = () => {
    fetch(process.env.REACT_APP_BASEURL + "/users/getStatus", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
    }).then(async (result) => {
      const data = await result.json();

      if (data.code === 200) {
        const aStatus = data.body;
        aStatus.sort(function (a, b) {
          return a.planAmount - b.planAmount;
        });
        setStatus(aStatus);
      }
    });
  };

  const resetForm = (e) => {
    e.preventDefault();
    setInputData(initialState);
    setTimeout(function () {
      setValidClass("col-md-8  needs-validation " + Date.now());
    }, 500);
  };

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();

      return;
    }
    e.preventDefault();

    fetch(process.env.REACT_APP_BASEURL + "/users/saveData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
      body: JSON.stringify(inputData),
    })
      .then(async (result) => {
        const data = await result.json();
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          resetForm(e);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  const onChangepaidAmount = (value) => {
    const remaAmount = inputData.planAmount - value;
    inputData.remainingAmount = remaAmount <= 0 ? "0" : remaAmount;
    inputData.paidAmount = value;
    setInputData({ ...inputData });
  };

  return (
    <div className="bodyNav row">
      <div className="col-md-10 border row mx-2  my-3 ">
        <div className="border mx-2  col-md-10 row justify-content-center">
          <form
            className={validClass}
            onKeyDown={onKeyDown}
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <MDBCardBody className="text-black d-flex flex-column justify-content-center">
              <h3 className="mb-5  mt-3 text-uppercase fw-bold">
                Fill Details to join us!
              </h3>

              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="First Name"
                    size="lg"
                    name="firstName"
                    maxLength={20}
                    required
                    value={inputData.firstName}
                    onChange={onChange}
                    type="text"
                  />
                </MDBCol>

                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Last Name"
                    size="lg"
                    name="lastName"
                    type="text"
                    maxLength={20}
                    required
                    value={inputData.lastName}
                    onChange={onChange}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Email"
                    size="lg"
                    name="email"
                    type="email"
                    required
                    maxLength={40}
                    value={inputData.email}
                    onChange={onChange}
                    autoComplete="off" 
                  />
                </MDBCol>

                <MDBCol
                  style={{ marginLeft: "2px" }}
                  className="row container"
                  md="6"
                >
                  <MDBInput
                    wrapperClass="col-3 text-center mb-4"
                    label="( +1 )"
                    size="lg"
                    name="countryCode"
                    type="tel"
                    required
                    maxLength={3}
                    readOnly={true}
                    value={inputData.countryCode}
                    onChange={onChange}
                  />

                  <MDBInput
                    wrapperClass="col-9 mb-4"
                    label="Phone Number"
                    size="lg"
                    name="phoneNumber"
                    type="tel"
                    required
                    maxLength={11}
                    value={inputData.phoneNumber}
                    onChange={onChange}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Zip Code"
                    size="lg"
                    name="zipCode"
                    required
                    type="text"
                    maxLength={8}
                    value={inputData.zipCode}
                    onChange={onChange}
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Number Of Family Members"
                    size="lg"
                    name="householdSize"
                    required
                    max={10}
                    min={1}
                    value={inputData.householdSize}
                    onChange={onChange}
                    type="number"
                  />
                </MDBCol>
              </MDBRow>

              <MDBInput
                wrapperClass="mb-4"
                label={
                  inputData.planAmount > 0 ? "Address" : "Address (Optional)"
                }
                size="lg"
                name="address"
                type="text"
                maxLength={256}
                required={inputData.planAmount > 0 ? true : false}
                value={inputData.address}
                onChange={onChange}
              />

              <div className=" mx-1 d-md-flex ustify-content-start align-items-center mb-1 row ">
                <label className="text-start mb-2">
                  <b>Select Member Status</b>
                </label>

                <select
                  required
                  onChange={(e) => {
                    onMemberSelection(e.target.value);
                  }}
                  name="status"
                  value={inputData.status}
                  className="form-select mb-3"
                  aria-label=".form-select-lg example"
                >
                  <option value="">Choose Member Status</option>

                  {allStatus.map((item) => (
                    <option value={item.id}>{item.statusName}</option>
                  ))}
                </select>
              </div>

              <hr></hr>
              <div style={{ display: paymentDiv ? "block" : "none" }}>
            
                <MDBRow>
                  <MDBCol md="6">
                    <MDBInput
                      wrapperClass="mb-4"
                      label="Total Due"
                      size="lg"
                      name="planAmount"
                      type="text"
                      readOnly={true}
                      className="col-md-6"
                      value={inputData.planAmount}
                      onChange={onChange}
                    />
                  </MDBCol>

                  <MDBCol md="6">
                    <MDBInput
                      wrapperClass="mb-4"
                      label="Remaining Amount"
                      size="lg"
                      name="remainingAmount"
                      type="text"
                      readOnly={true}
                      className="col-md-6"
                      value={inputData.remainingAmount}
                      onChange={onChange}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBInput
                  wrapperClass="mb-4"
                  label="Paid Amount"
                  size="lg"
                  name="paidAmount"
                  type="number"
                  required={inputData.planAmount > 0 ? true : false}
                  min={inputData.planAmount > 0 ? 1 : 0}
                  max={inputData.planAmount}
                  value={inputData.paidAmount}
                  onChange={(e) => onChangepaidAmount(e.target.value)}
                  autoFocus
                />
                <MDBRow>
                  <MDBCol
                    md={
                      inputData.planAmount > 0 && inputData.remainingAmount > 0
                        ? "6"
                        : "12"
                    }
                  >
                    <div className="d-md-flex ustify-content-start align-items-center mx-1 row mb-4">
                      <label className="text-start mb-2">
                        <b>Select Payment Type</b>
                      </label>

                      <select
                        onChange={onChange}
                        required={inputData.planAmount > 0 ? true : false}
                        name="paymentType"
                        value={inputData.paymentType}
                        className="form-select mb-3"
                        aria-label=".form-select-lg example"
                      >
                        <option value="">Choose Payment Type</option>

                        <option value="Check">Check</option>
                        <option value="Cash">Cash</option>
                        <option value="Card">Card</option>
                        <option value="Zelle">Zelle</option>
                        <option value="Ach/Wire">Ach/Wire</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </MDBCol>
                  <MDBCol
                    style={{
                      display:
                        inputData.planAmount > 0 &&
                        inputData.remainingAmount > 0
                          ? "inline"
                          : "none",
                    }}
                    md="6"
                  >
                    <div className="d-md-flex mx-1 ustify-content-start align-items-center mb-4 row">
                      <label className="text-start mb-2">
                        <b>Select Payment Plan</b>
                      </label>

                      <select
                        onChange={onChange}
                        required={
                          inputData.planAmount > 0 &&
                          inputData.remainingAmount > 0
                            ? true
                            : false
                        }
                        name="paymentPlan"
                        value={inputData.paymentPlan}
                        className="form-select mb-3"
                        aria-label=".form-select-lg example"
                      >
                        <option value="">Choose Payment Plan</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quaterly">Quaterly</option>
                        <option value="Semi-Anually">Semi-Anually</option>
                        <option value="Anually">Anually</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="6">
                    <MDBInput
                      wrapperClass="mb-4"
                      label="Contact Lead Name (Optional)"
                      size="lg"
                      name="leadName"
                      type="text"
                      maxLength={20}
                      className="col-md-6"
                      value={inputData.leadName}
                      onChange={onChange}
                    />
                  </MDBCol>
                  <MDBCol md="6">
                    <MDBInput
                      wrapperClass="mb-4"
                      label="Manager Verification Code"
                      size="lg"
                      name="mVCode"
                      type="text"
                      className="col-md-6"
                      maxLength={20}
                      required={inputData.planAmount > 0 ? true : false}
                      value={inputData.mVCode}
                      onChange={onChange}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="6">
                    <MDBInput
                      wrapperClass="mb-4"
                      label="Gotra (Optional)"
                      size="lg"
                      name="gotra"
                      type="text"
                      maxLength={20}
                      value={inputData.gotra}
                      onChange={onChange}
                    />
                  </MDBCol>

                  <MDBCol md="6">
                    <MDBTextArea
                      wrapperClass="mb-4"
                      value={inputData.comments}
                      onChange={onChange}
                      placeholder="Add your comments"
                      id="textAreaExample"
                      label="Comments (Optional)"
                      rows={2}
                      maxLength={500}
                      name="comments"
                    ></MDBTextArea>

                    {/* <MDBInput
                      wrapperClass="mb-4"
                      type="textarea"
                      rows={6}
                      name="comment"
                      label="Comment"
                      onChange={onChange}
                      value={inputData.comment}


                    /> */}
                  </MDBCol>
                </MDBRow>

                <div className="d-md-flex ustify-content-start align-items-center mb-4">
                  <label>Do you want to add spouse?</label>
                  <br></br>

                  <MDBRadio
                    className="mx-2"
                    name="isSpouseAdded"
                    id="yes"
                    value="true"
                    label="Yes"
                    onChange={onChange}
                    inline
                  />
                  <MDBRadio
                    className="mx-2"
                    name="isSpouseAdded"
                    id="no"
                    value="false"
                    onChange={onChange}
                    label="No"
                    inline
                  />
                </div>
                <MDBRow
                  style={{
                    display:
                      inputData.isSpouseAdded === "true" ? "flex" : "none",
                  }}
                >
                  <MDBCol md="6">
                    <MDBInput
                      wrapperClass="mb-4"
                      label="First Name"
                      size="lg"
                      name="spFirstName"
                      maxLength={20}
                      required={
                        inputData.isSpouseAdded === "true" ? true : false
                      }
                      value={inputData.spFirstName}
                      onChange={onChange}
                      type="text"
                    />
                  </MDBCol>

                  <MDBCol md="6">
                    <MDBInput
                      wrapperClass="mb-4"
                      label="Last Name"
                      size="lg"
                      name="spLastName"
                      type="text"
                      maxLength={20}
                      required={
                        inputData.isSpouseAdded === "true" ? true : false
                      }
                      value={inputData.spLastName}
                      onChange={onChange}
                    />
                  </MDBCol>
                </MDBRow>
              </div>
              <small className="text-secondary mt-5 px-5 pr-2">
                By providing my phone number, I agree to receive text message
                from {process.env.REACT_APP_NAME}. Standard message and data
                rates apply
              </small>

              <div className="justify-content-end pt-3 pb-2">
                <MDBBtn onClick={resetForm} color="secondary" size="m">
                  Reset all
                </MDBBtn>
                <MDBBtn
                  className="ms-1 px-4 pr-4"
                  style={{ backgroundColor: "#556b2f" }}
                  size="m"
                >
                  Join now
                </MDBBtn>
              </div>
            </MDBCardBody>
          </form>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Forms;
