import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { MDBCardImage } from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { toast } from "react-toastify";
import Modal from "react-modal";
import EditUser from "../admins/users/EditUser";
import { AppContext } from "../AppContext";
import { customStyles } from "../CustomStyle";
function UpdateUser() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    countryCode: "1",
    zipCode: "",
    householdSize: "",
    status: "",
    address: "",
    gotra: "",
  };
  const { userUpdated, setUserUpdated } = useContext(AppContext);

  const [timer, setTimer] = useState(60);
  const [count, setCount] = useState(0);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(60);
    }
  };

  const [userDetail, setuserDetail] = useState({});
  const [modalIsOpen, setModalOpen] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  const [inputData, setInputData] = useState(initialState);
  const phoneNumberOtp = useRef(null);
  const countryCodeRef = useRef(null);

  const otp = useRef(null);

  useEffect(() => {
    if (userUpdated) {
      setModalOpen(false);
      setUserUpdated(false);
      phoneNumberOtp.current.value = "";
      setInputData(initialState);
    }
  }, [userUpdated]);

  const handlePhoneSubmit = (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();
    setShowOtp(true);
    setTimer(60);

    setTimeout(() => {
      callApi();
    }, 100);
  };

  const callApi = () => {
    const phoneNumber = phoneNumberOtp.current.value;
    const countryCode = countryCodeRef.current.value;

    fetch(process.env.REACT_APP_BASEURL + "/users/sendOtp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
      body: JSON.stringify({ phoneNumber, countryCode }),
    })
      .then(async (result) => {
        const data = await result.json();

        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCount(count + 1);
          otp.current.value = "";
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    const phoneNumber = phoneNumberOtp.current.value;
    const countryCode = countryCodeRef.current.value;

    const getOtp = otp.current.value;
    // const otp=123456

    if (getOtp === "")
      return toast.error("Please enter OTP to proceed further", {
        position: toast.POSITION.TOP_RIGHT,
      });
    fetch(process.env.REACT_APP_BASEURL + "/users/verifyOtp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
      body: JSON.stringify({ phoneNumber, countryCode, otp: getOtp }),
    })
      .then(async (result) => {
        const data = await result.json();
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setuserDetail(data.body);
          setShowOtp(false);
          setModalOpen(true);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <div className="bodyNav row">
      <div className="col-md-10 border row mx-2  my-3">
        <div className="col-md-6">
          <MDBCardImage
            src="../update_form.png"
            alt="Sample photo"
            height={500}
            style={{ backgroundColor: "#324c73" }}
            className="rounded-start"
            fluid
          />
        </div>

        <div className="col-md-6 row justify-content-center">
          <form
            autoComplete="off"
            onSubmit={handlePhoneSubmit}
            className=" col-md-9 p-2"
          >
            <div
              className="mb-3"
              style={{ display: showOtp ? "none" : "inline" }}
            >
              <div>
                <b className="fs-5">
                  We will text you a one-time verification code to confirm your
                  number{" "}
                </b>
              </div>

              <label htmlFor="exampleInputEmail1" className="form-label  mt-4">
                Enter your Mobile phone number
              </label>

              <div class="row mt-2 mb-4 justify-content-center">
                <div class="col-3">
                  <input
                    type="text"
                    ref={countryCodeRef}
                    className="form-control text-center fs-4 "
                    id="countryCode"
                    name="countryCode"
                    placeholder="(1)"
                    value={inputData.countryCode}
                    maxLength={3}
                    onChange={onChange}
                    required
                  />{" "}
                </div>
                <div class="col-9">
                  <input
                    type="text"
                    ref={phoneNumberOtp}
                    className="form-control fs-4"
                    id="exampleInputEmail1"
                    name="name"
                    maxLength={15}
                    onChange={onChange}
                    required
                  />{" "}
                </div>
              </div>

              <small className="text-secondary mt-5">
                By providing my phone number, I agree to receive text message
                from {process.env.REACT_APP_NAME}. Standard message and data
                rates apply
              </small>
            </div>
            <div
              style={{ display: !showOtp ? "none" : "inline" }}
              className=" justify-content-center align-items-center"
            >
              <div className="card text-center">
                <div className="card-header p-5">
                  <img alt="otp" src="otp.jpg" />
                  <h5 className="mb-2 mt-2 ">OTP VERIFICATION</h5>
                  <div>
                    One Time Password (OTP) has been sent via SMS to <br></br>
                    <span className="fs-4 mt-4">
                      +
                      {countryCodeRef?.current?.value +
                        "-" +
                        phoneNumberOtp?.current?.value}
                    </span>
                  </div>
                </div>
                <p className="mt-4 text-success">
                  <b>Enter the OTP below to verify it</b>
                </p>

                <div className="input-container d-flex flex-row justify-content-center mt-2">
                  <input
                    style={{ fontSize: "25px !important" }}
                    ref={otp}
                    type="text"
                    className="m-1 fs-2 text-center form-control rounded"
                    maxlength="6"
                  />
                </div>
                <div
                  style={{ display: timer > 0 ? "none" : "inline" }}
                  className="mt-4 "
                >
                  <small className="fs-5 mt-2 ">
                    Didn't get the otp
                    <button className="btn btn-primary mx-2  btn-xs">
                      Resend OTP{" "}
                    </button>
                  </small>
                </div>
                <p
                  className="mt-5 text-info"
                  style={{ display: timer > 0 ? "inline" : "none" }}
                >
                  Resend OTP in {timer} seconds
                </p>

                <div className="mt-5 mb-5 ">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      resetTimer();
                      setShowOtp(false);
                      phoneNumberOtp.current.value = "";
                      setInputData(initialState);
                    }}
                    className="btn btn-danger  mx-2 mt-2 px-4"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      verifyOtp(e);
                    }}
                    className="btn btn-success  mt-2 px-4 "
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{ display: showOtp ? "none" : "block" }}
              className="col-md-12 row justify-content-center"
            >
              <button type="submit" className="btn  col-md-6 btn-primary mt-4 ">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div>
          <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            appElement={document.getElementById("root")}
            contentLabel="Example Modal"
          >
            <button onClick={(e) => setModalOpen(false)}>Close</button>

            <EditUser
              requestType={"fromUser"}
              userDetail={userDetail}
            ></EditUser>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UpdateUser;
