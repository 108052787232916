import React from "react";
// import { toast } from "react-toastify";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import cssexport default function ZipCodeItem({ contacts }) {
// import { AppContext } from "../AppContext";

export default function ZipCodeItem({contacts}) {
  // const {setZipCodeDeleted} = useContext(AppContext);

  // const deleteZipCode=(event,id)=>{
  //   confirmAlert({
  //     title: 'Confirmation',
  //     message: 'Are you sure to do this.',
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => acceptFunc(id)
  //       },
  //       {
  //         label: 'No',
  //         onClick: () => {}
  //       }
  //     ]
  // });


  // const acceptFunc=(id)=>{

  //      callAdminApi(process.env.REACT_APP_BASEURL + "/zipcodes/delete",
  //       "DELETE",
  //       { type: "json", body: JSON.stringify({
  //         id:id
  //       }), auth: true })

  //       .then(async (data) => {
  
  //         if (data.code === 200) {
  //           toast.success(data.message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
           
  //           setZipCodeDeleted(true)
  //         } else {
  //           toast.error(data.message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         toast.success(e.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       });
  
  //     }
  
  //   }

  
  return (
      <div
        id="customers"
        className="infowindow overflow-auto"
        style={{ height: "450px" }}
      >


        <table>
          <thead>
            <tr>
          <th>S No.</th>
          <th>Code</th>
          <th>Primary City</th>
          <th>County</th>
          <th>State</th>
          <th>Area Code</th>
          <th>Lead Name</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Status</th>
          {/* <th>Action</th> */}
          </tr>
          </thead>

<tbody>
          {
          contacts.map((user,index) => (
            <tr key={user.id}
            >
              
              <td>{index+1}</td>
              <td>{user.zipCode}</td>
              <td>{user.primaryCity}</td>
              <td>{user.county}</td>
              <td>{user.state}</td>
              <td>{user.areaCode}</td>
              <td>{user.lead?.name}</td>
              <td>{user.latitude}</td>
              <td>{user.longitude}</td>
              <td>{user.status===1?"Active":"Inactive"}</td>
              {/* <td><button  onClick={(e)=>deleteZipCode(e,user.id)}className="btn btn-danger btn-sm">Delete</button></td> */}

            </tr>
          ))}
          </tbody>
        </table>

      </div>
  );
          }

