import React, { useContext } from "react";
import { AppContext } from "../AppContext";

export default function ListItem({ contacts }) {
  const { checkItems, setCheckItems } = useContext(AppContext);

  const maangeSelectedItems = (checked) => {
    if (!checked) setCheckItems([...[]]);
    else {
      setCheckItems(contacts.map((item) => item.id));
    }
  };

  const checkUncheck = (checked, id) => {
    if (checked) checkItems.push(id);
    else {
      var index = checkItems.indexOf(id);

      if (index !== -1) {
        checkItems.splice(index, 1);
      }
    }
    setCheckItems([...checkItems]);
  };

  return (
    <div
      id="customers"
      className="infowindow overflow-auto"
      style={{ height: "500px" }}
    >
      <table>
        <thead>
          <tr>
            <th>
              <input
                className="form-check-input"
                type="checkbox"
                checked={checkItems.length > 0 ? "checked" : ""}
                onChange={(e) => maangeSelectedItems(e.target.checked)}
                name="selectAll"
              />
              Select {contacts.length} entries
            </th>

            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Zip Code</th>
            <th>City</th>
            <th>Member Status</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((user) => (
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) => checkUncheck(e.target.checked, user.id)}
                  checked={checkItems.includes(user.id) ? "checked" : ""}
                  name="selectedIds"
                ></input>
              </td>
              <td>{user.firstName + " " + user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.zipCode}</td>
              <td>{user.city}</td>
              <td>{user.mapStatusInfo?.statusName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
