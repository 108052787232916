import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../AppContext";
import { callAdminApi } from "../../API/ApiInterface";
import { toast } from "react-toastify";
import LoadingSpinner from "../../Spinner";
import SubAdminItem from "./SubAdminItem";
import {
  MDBBtn,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
export default function SubAdmins() {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState("");
  const [permissionList, updatePList] = useState([]);
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    countryCode: "1",
    role: "",
    password: "",
    cPassword: "",
    permissionList: permissionList,
  };
  const [inputData, setInputData] = useState(initialState);

  const {
    userUpdated,
    deletedUser,
    setUserDeleted,
    allModules,
    setUserUpdated,
  } = useContext(AppContext);

  useEffect(() => {
    setIsLoading(true);

    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/list", "POST", {
      body: JSON.stringify({
        search: search,
        role: role,
      }),
      type: "json",
    })
      .then(async (data) => {
        setIsLoading(false);

        if (data.code === 200) {
          const result = data.body;
          setContacts([...result.rows]);
          setUserUpdated(false);
          setUserDeleted(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);

        console.log(e);
      });
    // fetchApi()
  }, [search, role, deletedUser, userUpdated]);

  const resetForm = (e) => {
    e.preventDefault();
    setInputData(initialState);
  };

  const updatePermList = (checked, module) => {
    if (module === "all") {
      if (checked) {
        permissionList.push("all");
        allModules.map((item) => permissionList.push(item.moduleId));
      } else {
        permissionList.length = 0;
      }
    } else {
      if (checked) permissionList.push(module);
      else {
        var index = permissionList.indexOf(module);
        if (index !== -1) {
          permissionList.splice(index, 1);
        }

        if (permissionList.length === 1 && !checked) {
          var indexOfAll = permissionList.indexOf("all");
          if (indexOfAll !== -1) {
            permissionList.length = 0;
          }
        }
      }
    }

    updatePList([...permissionList]);
  };
  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();

    if (inputData.password !== inputData.cPassword) {
      return toast.error("Password and Confirm password doesn't match", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    inputData.permissionList=permissionList;

    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/create", "POST", {
      body: JSON.stringify(inputData),
      type: "json",
    })
      .then(async (data) => {
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setInputData(initialState);
          setUserUpdated(true);
          updatePList([]);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        console.log(e);

        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="bodyNav row">
        <div className="col-md-7 border row  my-3">
          <div className="col-md-3 mx-2">
            <select
              onChange={(e) => setRole(e.target.value)}
              className="form-select mt-2 p-2"
              aria-label=".form-select-lg example"
            >
              <option value="">Select Role</option>
              <option value="1"> Sub Admin</option>
              <option value="2">Managers</option>
            </select>
          </div>

          <div className="col-md-5 mx-2">
            <input
              type="text"
              placeholder="Search by (name,contact,email)"
              value={search}
              className="form-control mt-2 p-2"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="col-md-12 m-3">
            <div>
              <p
                style={{ float: "left", fontSize: "20px" }}
                className="float-left"
              >
                Total Count: {contacts.length}
              </p>
            </div>
            <SubAdminItem contacts={contacts} />
          </div>
        </div>

        <div className="col-md-5 border row mx-1  my-3">
          <form
            className="needs-validation"
            onSubmit={handleSubmit}
            autoComplete="off"
            noValidate
          >
            <MDBCardBody className="text-black d-flex flex-column justify-content-center">
              <h3 className="mb-5  mt-3 text-uppercase fw-bold">
                Fill Form to create sub admins!
              </h3>

              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="First Name"
                    size="lg"
                    name="firstName"
                    maxLength={20}
                    required
                    value={inputData.firstName}
                    onChange={onChange}
                    type="text"
                  />
                </MDBCol>

                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Last Name"
                    size="lg"
                    name="lastName"
                    type="text"
                    maxLength={20}
                    required
                    value={inputData.lastName}
                    onChange={onChange}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Password"
                    size="lg"
                    name="password"
                    type="password"
                    required
                    minLength={6}
                    maxLength={15}
                    value={inputData.password}
                    autoComplete="off" 
                    onChange={onChange}
                  />
                </MDBCol>

                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Confirm Password"
                    size="lg"
                    name="cPassword"
                    type="password"
                    required
                    minLength={6}
                    maxLength={15}
                    value={inputData.cPassword}
                    onChange={onChange}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Email"
                    size="lg"
                    name="email"
                    type="email"
                    required
                    maxLength={40}
                    autoComplete="off" 
                    value={inputData.email}
                    onChange={onChange}
                  />
                </MDBCol>

                <MDBCol
                  style={{ marginLeft: "2px" }}
                  className="row container"
                  md="6"
                >
                  <MDBInput
                    wrapperClass="col-3 text-center mb-4"
                    label="( +1 )"
                    size="lg"
                    name="countryCode"
                    type="tel"
                    required
                    maxLength={3}
                    value={inputData.countryCode}
                    onChange={onChange}
                  />

                  <MDBInput
                    wrapperClass="col-9 mb-4"
                    label="Phone Number"
                    size="lg"
                    name="phoneNumber"
                    type="tel"
                    required
                    maxLength={11}
                    value={inputData.phoneNumber}
                    onChange={onChange}
                  />
                </MDBCol>
              </MDBRow>

              <div className="d-md-flex justify-content-start align-items-center mb-4">
                <select
                  onChange={onChange}
                  required
                  name="role"
                  className="form-select mb-3"
                  aria-label=".form-select-lg example"
                >
                  <option value="">Choose Role</option>

                  <option value="1"> Sub Admin</option>

                  <option value="2"> Manager</option>
                </select>
              </div>

              <div className="col-md-12 row text-start">
                <div className="col-md-6 mt-1">
                  <input
                    checked={permissionList.includes("all") ? "checked" : ""}
                    onChange={(e) => updatePermList(e.target.checked, "all")}
                    id="module_all"
                    className="form-check-input  m-2"
                    type="checkbox"
                  />
                  Select All
                </div>

                {allModules.map((item, index) => (
                  <div className="col-md-6 mt-1">
                    <input
                      checked={
                        permissionList.includes(item.moduleId) ? "checked" : ""
                      }
                      onChange={(e) =>
                        updatePermList(e.target.checked, item.moduleId)
                      }
                      id={item.moduleId}
                      className="form-check-input  m-2"
                      type="checkbox"
                    />
                    {item.module?.moduleName}
                  </div>
                ))}

                <small className="text-secondary mt-2">
                  Note: Sub Module permission can be updated once admin is
                  created
                </small>
              </div>

              <div className="justify-content-end pt-3">
                <MDBBtn onClick={resetForm} color="info" size="m">
                  Reset all
                </MDBBtn>
                <MDBBtn className="ms-1 " color="warning" size="m">
                  Submit form
                </MDBBtn>
              </div>
            </MDBCardBody>
          </form>
        </div>
      </div>
    </>
  );
}
