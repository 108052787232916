import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import Modal from "react-modal";
import { customStyles } from "../../CustomStyle";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { callAdminApi } from "../../API/ApiInterface";
import EditTemplate from "./EditTemplate";
export default function TemplateItem({ templates }) {
  const { setUserDeleted, userUpdated } = useContext(AppContext);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [templateDetail, setTempalteDetail] = useState({});
 

  const editDetails = (id) => {
    const findItem = templates.find((item) => item.id === id);

    if (findItem) {
      setTempalteDetail(findItem);
      setModalOpen(true);
    }
  };

  useEffect(() => {
    setModalOpen(false);
  }, [userUpdated]);

  const deleteuser = (event, id) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => acceptFunc(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });

    const acceptFunc = (id) => {
      callAdminApi(
        process.env.REACT_APP_BASEURL + "/admin/templates/delete",
        "DELETE",
        {
          type: "json",
          auth: true,
          body: JSON.stringify({
            id: id,
          }),
          // body:JSON.stringify({leads:selctedIds})
        }
      )
        .then(async (data) => {
          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setUserDeleted(true);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          toast.success(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  };

  const getTextFromHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent ?? "";
  };

  return (
    <div
      id="customers"
      className="infowindow overflow-auto"
      style={{ height: "500px" }}
    >
      <table>
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Name</th>
            <th>Content</th>
            <th>Template For</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {templates.map((user, index) => (
            <tr key={"_" + index + "_" + user.id}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{getTextFromHtml(user.content)}</td>
              <td>{user.templateType === 1 ? "EMAIL" : "SMS"}</td>
              <td>{new Date(user.createdAt).toLocaleString()}</td>
              <td>
                <button
                  onClick={(e) => deleteuser(e, user.id)}
                  style={{
                    display: user.deleteOption === 1 ? "inline" : "none",
                  }}
                  className="btn btn-xs btn-danger"
                >
                  Delete
                </button>{" "}
                <button
                  onClick={(e) => editDetails(user.id)}
                  className="btn btn-info  mx-1 btn-xs"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        appElement={document.getElementById("root")}
        contentLabel="Example Modal"
      >
        <button onClick={(e) => setModalOpen(false)}>Close</button>

        <EditTemplate templateDetail={templateDetail}></EditTemplate>
      </Modal>
    </div>
  );
}
