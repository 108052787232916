import AnalysisItem from "./AnalysisItem";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { AppContext } from "../../AppContext";
import Pagination from "../../Pagination";
import { getAllLeads, callAdminApi } from "../../API/ApiInterface";
import { DateRangePicker } from "rsuite";
import { toast } from "react-toastify";
import LoadingSpinner from "../../Spinner";
export default function Analysis() {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [allLeads, setAllelads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [leadId, setSearchLeadId] = useState("");
  const [memberStatus, setMemberStatus] = useState("");
  const [rangeValue, setValue] = useState([]);
  const [recordsPerPage] = useState(30);
  const [nPages, setNPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [totalsum, setTotalSum] = useState(1);

  const [payload, setPayload] = useState({
    search: "",
    leadId: "",
    mapStatus: "",
    range: rangeValue,
    page: 1,
    limit: recordsPerPage,
  });
  const {
    userUpdated,
    allStatus,
    deletedUser,
    setUserDeleted,
    setUserUpdated,
  } = useContext(AppContext);

  const getAllLaeadsFxn = useCallback(() => {
    getAllLeads()
      .then(async (result) => {
        const data = await result.json();

        if (data.code === 200) {
          const filters = data.body;

          const array = [];

          filters?.leadInfo.map((item) => {
            return array.push({ id: item.id, name: item.name });
          });
          setAllelads([...array]);
          setUserDeleted(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const fetchApi = () => {
    setIsLoading(true);
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/vData/all", "POST", {
      type: "json",
      body: JSON.stringify(payload),
      auth: true,
    })
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          const resp = data.body;
          setContacts([...resp.rows]);
          const numberPages = Math.ceil(resp.count / recordsPerPage);
          setNPages(numberPages);
          setTotalRecords(resp.count);
          setTotalRecords(resp.count);
          setTotalSum(resp.sum);
          setUserDeleted(false);
          setUserUpdated(false);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    getAllLaeadsFxn();
  }, []);

  useEffect(() => {
    payload.leadId = leadId;
    payload.mapStatus = memberStatus;
    payload.search = search;
    payload.range = rangeValue;

    setPayload({ ...payload });
    setCurrentPage(1);
    fetchApi();
  }, [search, leadId, deletedUser, memberStatus, userUpdated, rangeValue]);

  useEffect(() => {
    payload.page = currentPage;
    payload.limit = recordsPerPage;

    fetchApi();
  }, [currentPage]);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="bodyNav row">
        <div className="col-md-11 border row mx-2  my-3">
          <b className="fs-3 m-1 text-start">User Analysis</b>

          <div className="col-md-3">
            <DateRangePicker
              onChange={setValue}
              placeholder="Select date range"
              className="form-control  p-2"
              editable={false}
              format="MM/dd/yyyy hh:mm aa"
              showMeridian
            />
          </div>
          <div className="col-md-3">
            <select
              onChange={(e) => setSearchLeadId(e.target.value)}
              className="form-select mt-2 p-2"
              aria-label=".form-select-lg example"
            >
              <option value="">Select Lead</option>
              {allLeads.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <select
              onChange={(e) => setMemberStatus(e.target.value)}
              className="form-select mt-2 p-2"
              aria-label=".form-select-lg example"
            >
              <option value="">Select Member Status</option>
              {allStatus.map((item) => (
                <option key={"status_" + item.id} value={item.id}>
                  {item.statusName}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <input
              type="text"
              placeholder="Search by (name,contact,email,zipcode)"
              value={search}
              className="form-control mt-2 p-2"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="col-md-12 m-3">
            <div>
              <p
                style={{ float: "left", fontSize: "20px" }}
                className="float-left"
              >
                Total Count: {totalRecords}
              </p>
            </div>

            <div>
              <p
                style={{ float: "left", fontSize: "20px" }}
                className="float-left mx-5 "
              >
                Total Sum: {totalsum}
              </p>
            </div>

            <AnalysisItem allStatus={allStatus} contacts={contacts} />
          </div>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
}
