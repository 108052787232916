import React, { useEffect, useState, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import { callAdminApi } from "../API/ApiInterface";
import LoadingSpinner from "../Spinner";
import Modal from "react-modal";
import Search from "../users/Search";
import { AppContext } from "../AppContext";
import { customStyles } from "../CustomStyle";
export default function LeadsControl() {
  const [allLeads, setAllelads] = useState([]);
  const [leadDeleted, setLeadDeleted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const { leadsUpdate, setLeadsUpdate } = useContext(AppContext);

  function closeModal() {
    setIsOpen(false);
  }
  const deleteLead = (event, id) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => acceptFunc(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });

    const acceptFunc = (id) => {
      callAdminApi(process.env.REACT_APP_BASEURL + "/leads/delete", "DELETE", {
        type: "json",
        body: JSON.stringify({
          id: id,
        }),
        auth: true,
      })
        .then(async (data) => {
          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setLeadDeleted(true);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          toast.success(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  };

  const restrictLeads = (status, id) => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/leads/changeLeadStatus",
      "POST",
      {
        auth: true,
        type: "json",
        body: JSON.stringify({
          status: status,
          id: id,
        }),
        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          getFilterInfo();
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        toast.success(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getFilterInfo = useCallback(() => {
    setLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/users/getFilterInfo?admin=1",
      "GET",
      {
        type: "json",
        body: null,
        auth: true,
      }
    )
      .then(async (data) => {
        setLoading(false);

        if (data.code === 200) {
          const filters = data.body;

          const array = [];

          filters?.leadInfo.map((item) => {
            return array.push({
              id: item.id,
              name: item.name,
              phoneNumber: item.phoneNumber ? item.phoneNumber : "NA",
              public: item.public,
            });
          });
          setAllelads([...array]);
          setLeadsUpdate(false);
          setLeadDeleted(false);
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  }, []);

  useEffect(() => {
    getFilterInfo();
    closeModal();
  }, [leadDeleted, leadsUpdate]);

  useEffect(() => {
    getFilterInfo();
  }, []);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="bodyNav container">
        <div className="col-md-12 row my-3">
          <div
            style={{ margin: "0 auto" }}
            className="col-md-5 border justify-content-center"
          >
            <h3 className="mt-3">Leads Control</h3>
            <hr></hr>
            <div
              id="customers"
              className="infowindow m-3 p-2 overflow-auto"
              style={{ height: "500px" }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Public</th>
                    <th>Lead Name</th>
                    <th>Lead Contact</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allLeads.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={item.public === 1 ? "checked" : ""}
                          onChange={(e) => {
                            restrictLeads(e.target.checked, item.id);
                          }}
                          name="selectedIds"
                        ></input>
                      </td>
                      <td>{item.name}</td>
                      <td>{item.phoneNumber}</td>
                      <td>
                        <button
                          style={{ display: item.id === 0 ? "none" : "" }}
                          onClick={(e) => deleteLead(e, item.id)}
                          className="btn btn-danger btn-sm"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button
              onClick={() => setIsOpen(true)}
              className="btn btn-success mt-2 mb-2"
            >
              {" "}
              Create New Lead
            </button>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <button onClick={closeModal}>close</button>

          <Search requestType="admin" pageType="admin" zipCode="0"></Search>
        </Modal>
      </div>
    </>
  );
}
