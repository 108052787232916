import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const Alert = (props) => {
  useEffect(() => {
    let type = props.data ? props.data.type : "success";
    let message = props.data ? props.data.message : null;

    if (!message) return;
    if (type === "success") {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "warning") {
      toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (type === "error") {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [props.data]);

  return (
    <>
      <ToastContainer />
    </>
  );
 
};
