import { createContext, useState, useEffect } from "react";
import { getAllStatus, callAdminApi } from "./API/ApiInterface";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { updateMemberList } from "../store/slices/memberStatusSlice";
import { updateLoginInfo } from "../store/slices/loginInfoSlice";

export const AppContext = createContext("");

export const AppContextProvider = ({ children }) => {
  const [leadsUpdate, setLeadsUpdate] = useState(false);
  const [checkItems, setCheckItems] = useState([]);
  const [deleteZipCode, setZipCodeDeleted] = useState(false);
  const [deletedUser, setUserDeleted] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const [allStatus, setAllStatus] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [publicInfo, setPublicInfo] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllStatus(1)
      .then(async (result) => {
        const data = await result.json();

        if (data.code === 200) {
          const filters = data.body;
          const array = [];
          filters?.map((item) => {
            return array.push({
              ...item,
              label: item.statusName,
              value: item.id,
              statusName: item.statusName,
              planAmount: item.planAmount,
            });
          });

          array.sort(function (a, b) {
            return a.planAmount - b.planAmount;
          });

          setAllStatus([...array]);
          dispatch(updateMemberList([...array]));
        }
      })
      .catch((err) => console.log(err));
    fetchAdminInfo();
    fetchPublicInfo();
  }, []);

  const fetchPublicInfo = () => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/publicInfo/getPublicInfoOptions",
      "GET",
      {
        type: "json",
        auth: true,

        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          const result = data.body;
          setPublicInfo([...result]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchAdminInfo = () => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/fetchProfileInfo",
      "GET",
      {
        type: "json",
        auth: true,

        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          const result = data.body;
          dispatch(updateLoginInfo(result));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkPermission = (moduleId, permissionCode) => {
    return new Promise((resolve, reject) => {
      callAdminApi(
        process.env.REACT_APP_BASEURL + "/admin/checkPermission",
        "POST",
        {
          type: "json",
          auth: true,
          body: JSON.stringify({ moduleId, permissionCode }),
        }
      )
        .then(async (data) => {
          if (data.code === 200) {
            resolve(true);
          } else if (data.code === 403) {
            swal("Oops!", data.message, "error");
            resolve(false);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  };

  const InitialContext = {
    allStatus,
    setAllStatus,
    leadsUpdate,
    publicInfo,
    allModules,
    setAllModules,
    isLoggedIn,
    setIsLoggedIn,
    setLeadsUpdate,
    checkItems,
    setCheckItems,
    deleteZipCode,
    setZipCodeDeleted,
    deletedUser,
    setUserDeleted,
    userUpdated,
    setUserUpdated,
    checkPermission,
  };

  return (
    <>
      <AppContext.Provider value={InitialContext}>
        {children}
      </AppContext.Provider>
    </>
  );
};
