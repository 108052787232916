import { callAdminApi } from "../API/ApiInterface";
import List from "../List";
import React, { useEffect, useState } from "react";
import Pagination from "../Pagination";

export default function Search(props) {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState(
    props.zipCode === "0" ? "1" : "0"
  );
  const [nPages, setNPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(200);
  const setSearchTypeFxn = (type) => {
    setSearchType(type);
  };

  useEffect(() => {
    let zipCodeSent = props.zipCode;
    if (searchType === "1") zipCodeSent = "";
    callAdminApi(process.env.REACT_APP_BASEURL + "/users/all", "POST", {
      type: "json",
      auth: true,
      body: JSON.stringify({
        searchType: searchType,
        search: search,
        zipCode: zipCodeSent,
        page: currentPage,
        limit: recordsPerPage,
      }),
      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (data) => {
        if (data.code === 200) {
          const result = data.body;
          setContacts([...result.rows]);
          const numberPages = Math.ceil(result.count / recordsPerPage);
          setNPages(numberPages);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [search, searchType, props.zipCode, currentPage]);

  // const filteredContacts = search.length === 0 ? contacts :
  // contacts.filter(contact => contact.full_name.
  //             toLowerCase().includes(search.toLowerCase()))
  return (
    <div className="col-md-12">
      <div className="mb-3">
        <input
          type="text"
          placeholder="Search name"
          value={search}
          className="form-control mt-2 p-2"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <select
        onChange={(e) => setSearchTypeFxn(e.target.value)}
        className="form-select form-select-lg mb-3"
        style={{
          float: "right",
          display:
            props.pageType === "admin" && props.zipCode !== "0"
              ? "display"
              : "none",
        }}
        aria-label=".form-select-lg example"
      >
        <option selected>Choose Option</option>
        <option selected={searchType === 0} value="0">
          Zip Code
        </option>
        <option selected={searchType === 1} value="1">
          Whole Database
        </option>
      </select>

      <List
        style={{ padding: "5px" }}
        requestType={props.requestType}
        pageType={props.pageType}
        contacts={contacts}
      />
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
