import React, { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { toast } from "react-toastify";
import { callAdminApi } from "../../API/ApiInterface";
import {
  MDBBtn,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
export default function EditAdmin({ userDetail }) {
  const initialState = {
    firstName: userDetail.firstName,
    lastName: userDetail.lastName,
    countryCode: userDetail.countryCode,
    phoneNumber: userDetail.phoneNumber,
    role: userDetail.role,
    adminId: userDetail.id,
  };

  const { setUserUpdated } = useContext(AppContext);
  const [inputData, setInputData] = useState(initialState);

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();

    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/EditData", "POST", {
      type: "json",
      body: JSON.stringify(inputData),
      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (data) => {
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setUserUpdated(true);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <div
      id="customers"
      className="infowindow overflow-auto"
      style={{ height: "600px" }}
    >
      <form
        className="needs-validation"
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
      >
        <MDBCardBody className="text-black d-flex flex-column justify-content-center">
          <h3 className="mb-5  mt-3 text-uppercase fw-bold">
            Fill Form to update details
          </h3>

          <MDBRow>
            <MDBCol md="6">
              <MDBInput
                wrapperClass="mb-4"
                label="First Name"
                size="lg"
                name="firstName"
                maxLength={20}
                required
                value={inputData.firstName}
                onChange={onChange}
                type="text"
              />
            </MDBCol>

            <MDBCol md="6">
              <MDBInput
                wrapperClass="mb-4"
                label="Last Name"
                size="lg"
                name="lastName"
                type="text"
                maxLength={20}
                required
                value={inputData.lastName}
                onChange={onChange}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol
              style={{ marginLeft: "2px" }}
              className="row container"
              md="6"
            >
              <MDBInput
                wrapperClass="col-3 text-center mb-4"
                label="( +1 )"
                size="lg"
                name="countryCode"
                type="tel"
                required
                maxLength={3}
                value={inputData.countryCode}
                onChange={onChange}
              />

              <MDBInput
                wrapperClass="col-9 mb-4"
                label="Phone Number"
                size="lg"
                name="phoneNumber"
                type="tel"
                required
                maxLength={11}
                value={inputData.phoneNumber}
                onChange={onChange}
              />
            </MDBCol>

            <MDBCol>
              <select
                onChange={onChange}
                required
                name="role"
                value={inputData.role}
                className="form-select-lg form-control"
                aria-label=".form-select-lg example"
              >
                <option value="">Choose Role</option>

                <option value="1"> Sub Admin</option>

                <option value="2"> Manager</option>
              </select>
            </MDBCol>
          </MDBRow>

          <div className="justify-content-end pt-3">
            <MDBBtn className="ms-1 " color="warning" size="m">
              Update Details
            </MDBBtn>
          </div>
        </MDBCardBody>
      </form>
    </div>
  );
}
