import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { callAdminApi } from "../API/ApiInterface";
import CommunityForm from "./CommunityForm";

export default function CommunityFormLargeView(props) {
  const [searchParams] = useSearchParams();
  const formId = searchParams.get("user-id");
  const [viewItem, setViewItem] = useState([]);

  useEffect(() => {
    getViewDetail();
  }, []);
  const getViewDetail = () => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/community/get-form/" + formId,
      "GET",
      {
        type: "json",
        auth: true,
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          const result = data.body;

          setViewItem(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
        <CommunityForm  textsize={80} action="update" viewitem={viewItem}></CommunityForm>
  );
}
