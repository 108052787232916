import "./App.css";

import { Alert } from "./components/Alert.js";
import Forms from "./components/users/Forms.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavbarTop from "./components/NavbarTop.js";

import Home from "./components/users/Home.js";
import Login from "./components/Login.js";
import AdminHome from "./components/admins/AdminHome.js";
import LeadsControl from "./components/admins/LeadsControl.js";
import { AppContextProvider } from "./components/AppContext.js";
import Email from "./components/admins/Email.js";
import ZipCodes from "./components/admins/ZipCodes.js";
import Scanner from "./components/users/Scanner.js";
import Users from "./components/admins/users/Users.js";
import AdminHomeTest from "./components/admins/AdminHomeTest.js";
import SubAdmins from "./components/admins/subAdmins/SubAdmins.js";
import ResetPassword from "./components/users/ResetPassword.js";
import Templates from "./components/admins/templates/Templates.js";
import Analysis from "./components/admins/analysis/Analysis.js";
import Status from "./components/admins/status/Status.js";
import PublicControl from "./components/admins/PublicControl.js";
import UserPermission from "./components/admins/users/UserPermission.js";
import UpdateUser from "./components/users/UpdateUser.js";
import Unsubscribe from "./components/users/Unsubscribe.js";
import CommunityHome from "./components/admins/community/CommunityHome.js";
import CommunityForm from "./components/users/CommunityForm.js";
import CommunityFormLargeView from "./components/users/CommunityFormLargeView.js";

function App() {
  return (
    <div className="App">
      <AppContextProvider>
        <Router>
          <Alert></Alert>
         
          <NavbarTop />

          <Routes>

            <Route path="/" index element={<Forms />}></Route>
            {/* <Route exact path="/" element={<Forms />} /> */}
            <Route exact path="/updateUser" element={<UpdateUser />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/unsubscribe" element={<Unsubscribe />} />
            <Route exact path="/community" element={<CommunityForm />} />
            <Route
              exact
              path="/get-community-form"
              element={<CommunityFormLargeView />}
            />
            <Route exact path="/admin/home" element={<AdminHome />} />
            <Route exact path="/admin/homeTest" element={<AdminHomeTest />} />
            <Route exact path="/admin/scanner" element={<Scanner />} />
            <Route exact path="/admin/login" element={<Login />} />
            <Route exact path="/admin/access" element={<LeadsControl />} />
            <Route exact path="/admin/email" element={<Email />} />
            <Route exact path="/admin/zipcodes" element={<ZipCodes />} />
            <Route exact path="/admin/users" element={<Users />} />
            <Route exact path="/admin/subAdmins" element={<SubAdmins />} />
            <Route
              exact
              path="/admin/resetPassword"
              element={<ResetPassword />}
            />
            <Route exact path="/admin/templates" element={<Templates />} />
            <Route exact path="/admin/analysis" element={<Analysis />} />
            <Route exact path="/admin/status" element={<Status />} />
            <Route
              exact
              path="/admin/user/permission"
              element={<UserPermission />}
            />
            <Route
              exact
              path="/admin/publiccontrol"
              element={<PublicControl />}
            />
            <Route exact path="/admin/community" element={<CommunityHome />} />
          </Routes>
        </Router>
      </AppContextProvider>
    </div>
  );
}

export default App;
