import { createSlice } from "@reduxjs/toolkit";

export const LoginInfoSlice = createSlice({
  name: "member_status",
  initialState: {
    data: {},
  },
  reducers: {
    updateLoginInfo: (state, action) => {
      state.data = { ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateLoginInfo } = LoginInfoSlice.actions;

export default LoginInfoSlice.reducer;
